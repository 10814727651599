import { Box, CircularProgress, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useApi } from '../hooks/useApi';
import { GetSettings } from '../api/SettingsApi';
import { url } from '../utils/url';
import { useNavigate } from 'react-router-dom';

export const TechModePage = () => {
  const GetSettingsApi = useApi(GetSettings);
  const navigate = useNavigate();
  const checkTechMode = async () => {
    const result = await GetSettingsApi.sendRequest();
    if (!result?.techMode) {
      navigate(url.login);
    }
  };
  useEffect(() => {
    checkTechMode();
  }, []);
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box sx={{ width: '500px', textAlign: 'center' }}>
        <CircularProgress sx={{ mb: 4 }} />
        <Typography as="h1" variant="h6">
          {t('TechModeTitle')}
        </Typography>
        <Typography>{t('TechModeDescription')}</Typography>
      </Box>
    </Box>
  );
};
