import React, { useEffect, useState } from 'react';
import { MainContainer } from '../components/Default/Layout/MainContainer';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import validator from 'validator';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { useApi } from '../hooks/useApi';
import { useNavigate } from 'react-router-dom';
import { url } from '../utils/url';
import { SignUp } from '../api/UserApi';
import { useDispatch } from 'react-redux';
import { setAlert } from '../store/alert.store';

export const RegisterPage = () => {
  const { t } = useTranslation();

  const [form, setForm] = useState({
    email: '',
    password: '',
    passwordAgain: '',
  });
  const [emailHelperText, setEmailHelperText] = useState(
    t('LoginEmailFieldHelperTextDefault'),
  );
  const [passwordHelperText, setPasswordHelperText] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const SignUpApi = useApi(SignUp);
  const navigate = useNavigate();
  useEffect(() => {
    if (form.email && !validator.isEmail(form.email)) {
      setEmailError(true);
      setEmailHelperText(t('LoginEmailFieldHelperTextError'));
    } else {
      setEmailError(false);
      setEmailHelperText(t('LoginEmailFieldHelperTextDefault'));
    }

    if (
      form.password &&
      form.passwordAgain &&
      form.password !== form.passwordAgain
    ) {
      setPasswordError(true);
      setPasswordHelperText(t('LoginPasswordFieldHelperTextError'));
    } else {
      setPasswordError(false);
      setPasswordHelperText('');
    }
  }, [form]);

  const dispatch = useDispatch();

  const SignUpHandler = async () => {
    try {
      const result = await SignUpApi.sendRequest({
        email: form.email,
        password: form.password,
      });

      if (result?.id) {
        dispatch(setAlert({ text: t('SuccessRegistration'), status: 200 }));
        navigate(url.login);
      }
    } catch (error) {}
  };
  return (
    <MainContainer needAuth={false}>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        sx={{ minHeight: '80vh' }}
      >
        <Grid item xs={12} sm={6} lg={4}>
          <Card variant="outlined" sx={{ borderRadius: '10px' }}>
            <CardContent>
              <Typography as="h1" variant="h6" sx={{ mb: 2 }}>
                {t('RegisterPageTitle')}
              </Typography>
              <TextField
                label={t('EmailFieldLabel')}
                helperText={emailHelperText}
                error={emailError}
                fullWidth
                sx={{ mb: 2 }}
                disabled={SignUpApi.loading}
                value={form.email}
                type="email"
                onChange={({ target: { value } }) =>
                  setForm({ ...form, email: value })
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                label={t('PasswordFieldLabel')}
                sx={{ mb: 2 }}
                type="password"
                fullWidth
                disabled={SignUpApi.loading}
                value={form.password}
                error={passwordError}
                onChange={({ target: { value } }) =>
                  setForm({ ...form, password: value })
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                label={t('PasswordAgainFieldLabel')}
                sx={{ mb: 2 }}
                type="password"
                fullWidth
                helperText={passwordHelperText}
                error={passwordError}
                disabled={SignUpApi.loading}
                value={form.passwordAgain}
                onChange={({ target: { value } }) =>
                  setForm({ ...form, passwordAgain: value })
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <LoadingButton
                variant="contained"
                color="primary"
                disabled={
                  emailError ||
                  passwordError ||
                  !form?.password ||
                  !form.passwordAgain
                }
                onClick={() => SignUpHandler()}
                loading={SignUpApi.loading}
              >
                {t('RegisterPageTitle')}
              </LoadingButton>
            </CardContent>
          </Card>
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="center"
            sx={{ mt: 2 }}
          >
            <Box>
              <Link
                color="inherit"
                sx={{ textDecoration: 'none', opacity: 0.5, cursor: 'pointer' }}
                onClick={() => navigate(url.login)}
              >
                {t('loginLink')}
              </Link>
            </Box>
            <Box>
              <Link
                color="inherit"
                sx={{ textDecoration: 'none', opacity: 0.5, cursor: 'pointer' }}
                onClick={() => navigate(url.forgotPassword)}
              >
                {t('forgotPasswordLink')}
              </Link>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </MainContainer>
  );
};
