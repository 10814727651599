import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setAlert } from '../store/alert.store';
import { useNavigate } from 'react-router-dom';
import { url } from '../utils/url';
import { useCookies } from 'react-cookie';
import { sub } from 'date-fns';
import { useTranslation } from 'react-i18next';

const defaultResponseParser = (response) => response;

export const useApi = (axiosMethod, responseParser = defaultResponseParser) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [cookie, setCookie, removeCookie] = useCookies(['jwt', 'lang']);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const sendRequest = useCallback(
    async (...params) => {
      try {
        setLoading(true);
        setError(null);
        const response = await axiosMethod(...params, cookie.lang);
        const result = responseParser(response);
        setData(result);
        return result;
      } catch (err) {
        setError(err);

        if (err?.response?.status == 500) {
          dispatch(setAlert(err));
        }
        if (err.code == 'ERR_NETWORK') {
          navigate(url.error500);
        }
        if (err?.response?.status == 401) {
          navigate(url.login);
          removeCookie('jwt', {
            expires: sub(new Date(), {
              days: 1800,
            }),
            path: '/',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [axiosMethod, responseParser],
  );

  return { data, error, loading, sendRequest };
};
