import { Alert, Box, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { removeAlert } from '../../../store/alert.store';
import styles from './AlertList.module.scss';
import { useTranslation } from 'react-i18next';
export const AlertsList = ({ alerts }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [openDetail, setOpenDetail] = useState(false);
  const [timeout, setTime] = useState(null);
  const [removeCandidate, setRemoveCandidate] = useState();
  const openDetailHandler = () => {
    setOpenDetail(!openDetail);
  };
  const removeAlerHandler = (id) => {
    const removeAlertTimer = setTimeout(() => {
      dispatch(removeAlert({ ...alert, id }));
    }, 900);
    return () => {
      clearTimeout(removeAlertTimer);
    };
  };
  useEffect(() => {
    if (alerts?.length) {
      const timeId = setTimeout(() => {
        setRemoveCandidate(alerts[alerts?.length - 1]?.id);
        removeAlerHandler(alerts[alerts?.length - 1]?.id);
      }, 5000);
      setTime(timeId);
      return () => {
        clearTimeout(timeId);
      };
    }
  }, [alerts]);
  return (
    <div className={styles.AlertsList}>
      <Box sx={{ position: 'relative' }}>
        {alerts?.map((alert, index) => (
          <Alert
            key={index}
            severity={alert.type}
            color={alert.type}
            className={styles.AlertsList__Item}
            onClose={() => {
              setRemoveCandidate(alert.id);
              removeAlerHandler(alert.id);
            }}
            sx={{
              transform: `translateX(${
                removeCandidate == alert.id ? '-100%' : 0
              })`,
              opacity: removeCandidate == alert.id ? 0 : 1,
              transition: removeCandidate == alert.id ? '1s' : 0,
            }}
          >
            {alert.text}
            {alert.message && (
              <span style={{ display: 'block', margin: '5px 0' }}>
                <i>{alert.message}</i>
              </span>
            )}
            <br />
            {alert.details && alert.message && (
              <>
                {openDetail && (
                  <span style={{ display: 'block', margin: '5px 0' }}>
                    <i>{alert.details}</i>
                  </span>
                )}
                <Button onClick={openDetailHandler} color={alert.type}>
                  {openDetail ? t('Collapse') : t('Подробнее')}
                </Button>
              </>
            )}
          </Alert>
        ))}
      </Box>
    </div>
  );
};
