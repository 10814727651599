import {
  Chip,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { differenceInCalendarDays, format } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useResponsive } from '../../hooks/useResponsive';

export const InvoicesTable = ({ invoices, loading, transactions, check }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isMobile } = useResponsive();

  const transactionHandler = (invoice) => {
    const transaction = transactions?.All?.find(
      (t) =>
        t.TransDate == invoice.InvoiceDate &&
        t.Voucher == invoice.LedgerVoucher,
    );
    return transaction?.AmountCur - transaction?.SettleAmountCur
      ? (transaction?.AmountCur - transaction?.SettleAmountCur).toFixed(2) +
          ' ' +
          invoice?.CurrencyCode
      : '';
  };
  return (
    <>
      {loading ? (
        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map(
          (skeleton) => (
            <Skeleton
              variant="rounded"
              sx={{ width: '100%', mb: 0.1 }}
              height={40}
            />
          ),
        )
      ) : invoices?.length ? (
        <TableContainer
          component={Paper}
          sx={{ width: isMobile ? '90%' : '100%' }}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{t('InvoiceId')}</TableCell>

                <TableCell>{t('InvoiceAmount')}</TableCell>
                <TableCell>{t('ForPayment')}</TableCell>
                <TableCell>{t('DueDate')}</TableCell>
                <TableCell>{t('InvoiceDate')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoices.map((invoice) => (
                <TableRow
                  key={invoice?.InvoiceId}
                  onClick={() =>
                    navigate(`/invoices?InvoiceId=${invoice?.InvoiceId}`)
                  }
                  sx={{
                    '&:last-child td, &:last-child th': {
                      border: 0,
                    },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {invoice?.InvoiceId}{' '}
                    {check &&
                      check?.nearPayment?.some(
                        (inv) => inv.InvoiceId == invoice.InvoiceId,
                      ) && (
                        <Chip
                          label={
                            t('nearPaymentStatus') +
                            ' - ' +
                            ' ' +
                            differenceInCalendarDays(
                              new Date(invoice?.DueDate),
                              new Date(),
                            ) +
                            t('days')
                          }
                          size="small"
                          color="warning"
                        />
                      )}
                    {check &&
                      check?.noPayment?.some(
                        (inv) => inv.InvoiceId == invoice.InvoiceId,
                      ) && (
                        <Chip
                          label={
                            t('noPaymentStatus') +
                            ' ' +
                            t('on') +
                            ' ' +
                            differenceInCalendarDays(
                              new Date(),
                              new Date(invoice?.DueDate),
                            ) +
                            t('days')
                          }
                          size="small"
                          color="error"
                        />
                      )}
                    {check &&
                      transactions?.All?.find(
                        (t) =>
                          t.TransDate == invoice.InvoiceDate &&
                          t.Voucher == invoice.LedgerVoucher,
                      ) &&
                      !check?.noPayment?.some(
                        (inv) => inv.InvoiceId == invoice.InvoiceId,
                      ) &&
                      !check?.nearPayment?.some(
                        (inv) => inv.InvoiceId == invoice.InvoiceId,
                      ) && (
                        <Chip
                          label={
                            t('PaymentPeriod') +
                            ' ' +
                            differenceInCalendarDays(
                              new Date(invoice?.DueDate),
                              new Date(),
                            ) +
                            t('days')
                          }
                          size="small"
                          color="success"
                        />
                      )}
                  </TableCell>
                  <TableCell>
                    {invoice?.InvoiceAmount + ' ' + invoice?.CurrencyCode}
                  </TableCell>
                  <TableCell>{transactionHandler(invoice)}</TableCell>
                  <TableCell>
                    {invoice?.DueDate == '1900-01-01T12:00:00Z'
                      ? ''
                      : format(invoice?.DueDate, 'dd.MM.yyyy')}
                  </TableCell>

                  <TableCell>
                    {invoice?.InvoiceDate == '1900-01-01T12:00:00Z'
                      ? ''
                      : format(invoice?.InvoiceDate, 'dd.MM.yyyy')}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography>{t('OrdersNotFound')}</Typography>
      )}
    </>
  );
};
