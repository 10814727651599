import React, { useEffect, useState } from 'react';
import { MainContainer } from '../components/Default/Layout/MainContainer';
import { useApi } from '../hooks/useApi';
import {
  CheckUnpaymentInvoices,
  GetMyDrafts,
  GetMyOrders,
  PostDraftOrder,
} from '../api/OrderApi';
import { useCookies } from 'react-cookie';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  Pagination,
  Paper,
  Skeleton,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { OrderItem } from '../components/Order/OrderItem';
import { useTranslation } from 'react-i18next';
import TabList from '@mui/lab/TabList';
import TabContext from '@mui/lab/TabContext';

import TabPanel from '@mui/lab/TabPanel';
import { useLocation, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { url } from '../utils/url';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import { useResponsive } from '../hooks/useResponsive';
import en from '../i18n/en.json';
import ru from '../i18n/ru.json';
export const OrderListPage = () => {
  const { isMobile, isTablet, isDesktop } = useResponsive();
  const [cookie, setCookie] = useCookies(['jwt', 'ordersView']);
  const [orders, setOrders] = useState([]);
  const [drafts, setDrafts] = useState([]);
  const [draftCOunt, setDraftCount] = useState(0);
  const [skip, setSkip] = useState(0);
  const [skipDraft, setSkipDraft] = useState(0);
  const [orderView, setOrderView] = useState(cookie.ordersView || 'cards');

  const [tab, setTab] = useState(0);
  const { t, i18n } = useTranslation();
  const GetMyOrdersApi = useApi(GetMyOrders);
  const GetMyDraftsApi = useApi(GetMyDrafts);
  const CreateDraftOrderApi = useApi(PostDraftOrder);
  const [check, setCheck] = useState({});
  const [searchOrder, setSearchOrder] = useState('');
  const location = useLocation();

  const GetMyOrdersHandler = async ({
    s = skip,
    top = 16,
    search = searchOrder,
  }) => {
    const result = await GetMyOrdersApi.sendRequest({
      token: cookie.jwt,
      skip: s,
      top,
      search,
    });
    setOrders(result);
  };

  useEffect(() => {
    if (location.pathname.indexOf('draft') > 0) {
      setTab(1);
    } else if (location.pathname.indexOf('full') > 0) {
      setTab(0);
    }
  }, [location]);

  const GetMyDraftsHandler = async ({ s = skipDraft, top = 16 }) => {
    try {
      const result = await GetMyDraftsApi.sendRequest({
        token: cookie.jwt,
        skip: s,
        top,
      });
      setDrafts(result[0]);
      setDraftCount(result[1]);
    } catch (error) {}
  };

  useEffect(() => {
    if (cookie.jwt) {
      GetMyOrdersHandler({ s: skip, top: 16 });
    }
  }, [skip]);

  useEffect(() => {
    if (cookie.jwt) {
      GetMyDraftsHandler({ token: cookie.jwt, s: skipDraft, top: 16 });
    }
  }, [skipDraft]);
  const navigate = useNavigate();

  const createDrafOrderHandler = async () => {
    const result = await CreateDraftOrderApi.sendRequest({ token: cookie.jwt });

    if (result?.id) navigate(`/orders/draft/${result.id}`);
  };

  return (
    <MainContainer needAuth={true} setCheckhandler={(data) => setCheck(data)}>
      <TabContext value={tab}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stack
              direction={isDesktop ? 'row' : 'column'}
              spacing={2}
              justifyContent="space-between"
              alignItems="center"
              sx={{ width: '100%' }}
            >
              <Box sx={{ width: isDesktop ? '33.33%' : '100%' }}>
                <Typography
                  as="h1"
                  variant="h4"
                  sx={{ mb: 2, fontWeight: 'bold' }}
                >
                  {t('OrderListTitle')}
                </Typography>
              </Box>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: 'divider',
                  width: isDesktop ? '33.33%' : '100%',
                }}
              >
                <TabList
                  value={tab}
                  onChange={(event, newValue) => setTab(newValue)}
                  aria-label="basic tabs example"
                >
                  <Tab
                    value={0}
                    label={t('PlacedOrders')}
                    onClick={() => navigate(url.orders)}
                  />
                  <Tab
                    value={1}
                    label={t('UnfinishedOrders')}
                    onClick={() => navigate(url.ordersDraft)}
                  />
                </TabList>
              </Box>
              <Box
                sx={{ width: isDesktop ? '33.33%' : '100%', display: 'flex' }}
              >
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{ ml: isDesktop ? 'auto' : 0 }}
                  alignItems="center"
                >
                  <ToggleButtonGroup
                    value={orderView}
                    exclusive
                    onChange={(event, newAlignment) => {
                      setOrderView(newAlignment);
                      setCookie('ordersView', newAlignment, { path: '/' });
                    }}
                    aria-label="text alignment"
                  >
                    <ToggleButton
                      value="table"
                      aria-label="left aligned"
                      size="small"
                    >
                      <ListOutlinedIcon />
                    </ToggleButton>
                    <ToggleButton
                      value="cards"
                      aria-label="centered"
                      size="small"
                    >
                      <AppsOutlinedIcon />
                    </ToggleButton>
                  </ToggleButtonGroup>
                  <Box>
                    <Button
                      variant="outlined"
                      color="success"
                      onClick={() => createDrafOrderHandler()}
                    >
                      {t('CreateOrderButton')}
                    </Button>
                  </Box>
                </Stack>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            {!!check?.noPayment?.length && (
              <Alert color="error" severity="error" sx={{ mb: 1 }}>
                <Typography>{t('UnpaidInvoicesAlert')}</Typography>
              </Alert>
            )}
            {!!check?.nearPayment?.length && (
              <Alert color="warning" severity="warning" sx={{ mb: 1 }}>
                <Typography>{t('DueDateAlert')}</Typography>
              </Alert>
            )}
          </Grid>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                sx={{ px: 3 }}
              >
                <TextField
                  label={t('SearchOrders')}
                  fullWidth
                  size="small"
                  value={searchOrder}
                  onChange={({ target: { value } }) => setSearchOrder(value)}
                />
                <Button
                  color="success"
                  variant="outlined"
                  onClick={() =>
                    GetMyOrdersHandler({
                      s: 0,
                      top: searchOrder ? 9999 : 16,
                    })
                  }
                >
                  {t('Search')}
                </Button>
              </Stack>
            </Grid>
          </Grid>
          <TabPanel value={0} sx={{ width: '100%' }}>
            {orderView == 'table' ? (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {GetMyOrdersApi.loading ? (
                    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map(
                      (skeleton) => (
                        <Skeleton
                          variant="rounded"
                          sx={{ width: '100%', mb: 0.1 }}
                          height={40}
                        />
                      ),
                    )
                  ) : orders?.length ? (
                    <TableContainer
                      component={Paper}
                      sx={{ maxWidth: isDesktop ? '100%' : '90%' }}
                    >
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>{t('SalesOrderNumber')}</TableCell>
                            <TableCell>
                              {t('CustomerRequisitionNumber')}
                            </TableCell>
                            <TableCell>{t('OrderAmount')}</TableCell>
                            <TableCell>{t('SalesOrderStatus')}</TableCell>
                            <TableCell>{t('OrderCreationDateTime')}</TableCell>
                            <TableCell>{t('DeliveryDate')}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {orders.map((order) => (
                            <TableRow
                              key={order?.SalesOrderNumber}
                              onClick={() =>
                                navigate(
                                  `/orders/full/${order?.SalesOrderNumber}`,
                                )
                              }
                              sx={{
                                '&:last-child td, &:last-child th': {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {order?.SalesOrderNumber}
                              </TableCell>
                              <TableCell>
                                {order?.CustomerRequisitionNumber || '-'}
                              </TableCell>
                              <TableCell>
                                {order?.OrderTotalAmount +
                                  ' ' +
                                  order?.CurrencyCode}
                              </TableCell>
                              <TableCell>
                                {order?.ConfirmedShippingDate !=
                                  '1900-01-01T12:00:00Z' &&
                                  order?.SalesOrderStatus == 'Backorder' && (
                                    <Chip
                                      label={t('CustomInProgress')}
                                      color="success"
                                      size="small"
                                      sx={{ mr: 1 }}
                                    />
                                  )}
                                <Chip
                                  label={t(order?.SalesOrderStatus)}
                                  size="small"
                                />
                              </TableCell>
                              <TableCell>
                                {order?.OrderCreationDateTime ==
                                '1900-01-01T12:00:00Z'
                                  ? ''
                                  : format(
                                      order?.OrderCreationDateTime,
                                      'dd.MM.yyyy',
                                    )}
                              </TableCell>
                              <TableCell>
                                {order?.ConfirmedShippingDate &&
                                order?.ConfirmedShippingDate !==
                                  '1900-01-01T12:00:00Z'
                                  ? format(
                                      order?.ConfirmedShippingDate,
                                      'dd.MM.yyyy',
                                    )
                                  : order?.RequestedShippingDate &&
                                    order?.RequestedShippingDate !==
                                      '1900-01-01T12:00:00Z'
                                  ? format(
                                      order?.RequestedShippingDate,
                                      'dd.MM.yyyy',
                                    )
                                  : ''}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <Typography>{t('OrdersNotFound')}</Typography>
                  )}
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={2}>
                {!GetMyOrdersApi.loading ? (
                  orders?.length ? (
                    orders?.map((order) => (
                      <Grid item xs={12} sm={6} lg={3}>
                        <OrderItem order={order} />
                      </Grid>
                    ))
                  ) : (
                    <Grid item xs={12}>
                      <Typography>{t('OrderNotFound')}</Typography>
                    </Grid>
                  )
                ) : (
                  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map(
                    (skeleton) => (
                      <Grid item xs={12} sm={6} lg={3}>
                        <Skeleton
                          variant="rounded"
                          sx={{ width: '100%' }}
                          height={118.91}
                        />
                      </Grid>
                    ),
                  )
                )}
              </Grid>
            )}
            {!searchOrder && (
              <Grid container spacing={2} sx={{ mt: 3 }}>
                <Grid item xs={12} sx={{ pb: 3 }}>
                  <Stack direction="row" spacing={2}>
                    {skip > 0 && (
                      <Button
                        variant="outlined"
                        onClick={() => setSkip(skip - 16)}
                      >
                        {t('Back')}
                      </Button>
                    )}
                    {!!orders?.length && (
                      <Button
                        variant="outlined"
                        onClick={() => setSkip(skip + 16)}
                      >
                        {t('Continue')}
                      </Button>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            )}
          </TabPanel>
          <TabPanel value={1} sx={{ width: '100%' }}>
            {orderView === 'table' ? (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {GetMyDraftsApi.loading ? (
                    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map(
                      (skeleton) => (
                        <Skeleton
                          variant="rounded"
                          sx={{ width: '100%', mb: 0.1 }}
                          height={40}
                        />
                      ),
                    )
                  ) : drafts?.length ? (
                    <TableContainer component={Paper}>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>{t('OrderCreationDateTime')}</TableCell>
                            <TableCell>{t('Items')}</TableCell>
                            <TableCell>
                              {t('CustomerRequisitionNumber')}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {drafts.map((draft) => (
                            <TableRow
                              key={draft?.id}
                              onClick={() =>
                                navigate(`/orders/draft/${draft?.id}`)
                              }
                              sx={{
                                '&:last-child td, &:last-child th': {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell>
                                {format(draft?.created, 'dd.MM.yyyy')}
                              </TableCell>
                              <TableCell>{draft?.lines?.length}</TableCell>
                              <TableCell component="th" scope="row">
                                {draft?.CustomerRequisitionNumber || '-'}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <Typography>{t('OrdersNotFound')}</Typography>
                  )}
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={2}>
                {drafts?.map((draft) => (
                  <Grid item lg={3} xs={12} sm={6}>
                    <Card onClick={() => navigate(`/orders/draft/${draft.id}`)}>
                      <CardContent>
                        <Stack
                          direction="row"
                          spacing={2}
                          justifyContent="space-between"
                          alignItems="start"
                        >
                          <Typography>
                            {t('OrderFrom')}{' '}
                            {format(draft?.created, 'dd.MM.yyyy')}
                          </Typography>
                          <Box>
                            <Typography>
                              {draft?.CustomerRequisitionNumber?.length > 10
                                ? draft?.CustomerRequisitionNumber?.slice(
                                    0,
                                    10,
                                  ) + '...'
                                : draft?.CustomerRequisitionNumber}
                            </Typography>
                          </Box>
                        </Stack>
                        <Chip
                          label={`${t('TotalProducts')}: ${
                            draft?.lines?.length || 0
                          } ${t('pc')}`}
                          sx={{ mt: 1 }}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            )}
            <Grid item xs={12}>
              {Math.round(draftCOunt / 16) > 1 && (
                <Pagination
                  sx={{ mt: 3, mb: 5 }}
                  count={Math.round(draftCOunt / 16)}
                  page={skipDraft}
                  onChange={(event, value) => {
                    setSkipDraft((value - 1) * 16);
                    GetMyDraftsHandler({
                      token: cookie.jwt,
                      skip: 16 * value,
                      top: 16,
                    });
                  }}
                />
              )}
            </Grid>
          </TabPanel>
        </Grid>
      </TabContext>
    </MainContainer>
  );
};
