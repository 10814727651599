import React, { useEffect, useState } from 'react';
import { MainContainer } from '../components/Default/Layout/MainContainer';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  CircularProgress,
  Container,
  Divider,
  Drawer,
  Fab,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  Pagination,
  Select,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useApi } from '../hooks/useApi';
import { GetCategories, GetProducts, GetUnitsOfMeasure } from '../api/PriceApi';
import { useCookies } from 'react-cookie';
import validator from 'validator';
import {
  CreateOrderToD365,
  GetAddresses,
  GetDraftById,
  PostDraftOrder,
  RemoveDraftOrder,
} from '../api/OrderApi';
import { useNavigate, useParams } from 'react-router-dom';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { enGB } from 'date-fns/locale';
import { OrderProductItem } from '../components/Order/OrderProductItem';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import { useDispatch } from 'react-redux';
import { setAlert } from '../store/alert.store';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { LoadingButton } from '@mui/lab';
import { BoxAndPalletReducer } from '../utils/BoxAndPalletReducer';
import { add, differenceInDays, format } from 'date-fns';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { useTreeViewApiRef } from '@mui/x-tree-view/hooks';
import { Sticky, StickyContainer } from 'react-sticky';
import { useResponsive } from '../hooks/useResponsive';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
function getItemDescendantsIds(item) {
  const ids = [];
  item.children?.forEach((child) => {
    ids.push(child.id);
    ids.push(...getItemDescendantsIds(child));
  });

  return ids;
}

export const CreateOrder = () => {
  const { isMobile, isTablet, isDesktop } = useResponsive();
  const { t, i18n } = useTranslation();
  const [openConfirmOrder, setOpenConfirmOrder] = useState(false);
  const [openDeleteOrder, setOpenDeleteOrder] = useState(false);
  const [categories, setCategories] = useState([]);
  const [prices, setPrices] = useState([]);
  const [priceCount, setPriceCount] = useState(0);
  const [page, setPage] = useState(1);
  const [otherOrderData, setOtherData] = useState({});
  const [openMobileFilter, setOpenMobileFilter] = useState(false);
  const [categorySearch, setCategorySearch] = useState('');
  const [commentMaxLength, setCommentMaxLength] = useState(0);

  useEffect(() => {
    setCommentMaxLength(152 - t('DeliveryRequired')?.length);
  }, []);
  const [filter, setFilter] = useState({
    search: '',
    categories: [],
    top: 16,
    skip: 0,
  });
  const [selectedOrderPrice, setSelectedOrderPrice] = useState([]);
  const [openSelectProductsModal, setOpenSelectProductsModal] = useState(false);
  const GetCategoriesApi = useApi(GetCategories);
  const GetProductsApi = useApi(GetProducts);
  const EditDraftOrderApi = useApi(PostDraftOrder);
  const GetDraftByIdApi = useApi(GetDraftById);
  const GetAddressesApi = useApi(GetAddresses);
  const RemoveDraftOrderApi = useApi(RemoveDraftOrder);
  const GetUnitsOfMeasureApi = useApi(GetUnitsOfMeasure);
  const CreateOrderToD365Api = useApi(CreateOrderToD365);
  const [addresses, setAddresses] = useState([]);
  const [dateWasChange, setDateWasChange] = useState(false);
  const [unitsOfMeasureState, setUnitsOfMeasureState] = useState([]);
  const [commentError, setCommentError] = useState(false);
  const [cookie] = useCookies('jwt');
  const { draftId } = useParams();
  const changeItemCountField = async (price, Factor) => {
    if (selectedOrderPrice?.find((p) => p.id === price.id)) {
      const lines = selectedOrderPrice?.map((p) => {
        if (p.priceId == price.generatedId) {
          return {
            ...p,
            count: Factor,
            releasedProduct: {
              ...p.releasedProduct,
              units: price?.releasedProduct?.units,
              unitsOfMeasure: price?.releasedProduct?.unitsOfMeasure,
            },
          };
        } else {
          return {
            ...p,
            releasedProduct: {
              ...p.releasedProduct,
              units: price?.releasedProduct?.units,
              unitsOfMeasure: price?.releasedProduct?.unitsOfMeasure,
            },
          };
        }
      });
      await EditDraftOrderApi.sendRequest({
        token: cookie.jwt,
        body: {
          id: draftId,
          lines: lines?.map((p) => ({
            priceId: p.generatedId,
            ItemNumber: p.ItemNumber,
            OrderedSalesQuantity: p.count || 0,
            SalesUnitSymbol: p.QuantityUnitySymbol,
          })),
        },
      });

      setSelectedOrderPrice(
        lines.map((l) => ({
          ...l,
          units: price?.releasedProduct?.units,
          unitsOfMeasure: price?.releasedProduct?.unitsOfMeasure,
        })),
      );
    } else {
      const lines = [
        ...selectedOrderPrice,
        { ...price, priceId: price.generatedId, count: Factor },
      ];

      await EditDraftOrderApi.sendRequest({
        token: cookie.jwt,
        body: {
          id: draftId,
          lines: lines?.map((p) => ({
            SalesOrderNumber: '',
            priceId: p.generatedId,
            ItemNumber: p.ItemNumber,
            OrderedSalesQuantity: p.count,
            SalesUnitSymbol: p.QuantityUnitySymbol,
          })),
        },
      });
      setSelectedOrderPrice(
        lines.map((l) => ({
          ...l,
          units: price?.releasedProduct?.units,
          unitsOfMeasure: price?.releasedProduct?.unitsOfMeasure,
        })),
      );
    }
  };
  const removeItem = async (price) => {
    const lines = selectedOrderPrice?.filter((item) => item.id !== price.id);
    await EditDraftOrderApi.sendRequest({
      token: cookie.jwt,
      body: {
        id: draftId,
        lines: lines?.map((p) => ({
          SalesOrderNumber: '',
          priceId: p.generatedId,
          ItemNumber: p.ItemNumber,
          OrderedSalesQuantity: p.count,
          SalesUnitSymbol: p.QuantityUnitySymbol,
        })),
      },
    });
    setSelectedOrderPrice(
      lines.map((l) => ({
        ...l,
        units: price?.releasedProduct?.units,
        unitsOfMeasure: price?.releasedProduct?.unitsOfMeasure,
      })),
    );
  };
  const sumProduct = async (price, Factor) => {
    if (selectedOrderPrice?.find((p) => p.id === price.id)) {
      const lines = selectedOrderPrice?.map((p) => {
        if (p.priceId == price.generatedId) {
          return {
            ...p,
            count: (p?.count || 0) + Factor,
            releasedProduct: {
              ...p.releasedProduct,
              units: price?.releasedProduct?.units,
              unitsOfMeasure: price?.releasedProduct?.unitsOfMeasure,
            },
          };
        } else {
          return {
            ...p,
            releasedProduct: {
              ...p.releasedProduct,
              units: price?.releasedProduct?.units,
              unitsOfMeasure: price?.releasedProduct?.unitsOfMeasure,
            },
          };
        }
      });

      await EditDraftOrderApi.sendRequest({
        token: cookie.jwt,
        body: {
          id: draftId,
          lines: lines?.map((p) => ({
            // SalesOrderNumber: '',
            priceId: p.generatedId,
            ItemNumber: p.ItemNumber,
            OrderedSalesQuantity: p.count,
            SalesUnitSymbol: p.QuantityUnitySymbol,
          })),
        },
      });

      setSelectedOrderPrice(
        lines.map((l) => ({
          ...l,
          units: price?.releasedProduct?.units,
          unitsOfMeasure: price?.releasedProduct?.unitsOfMeasure,
        })),
      );
    } else {
      const lines = [
        ...selectedOrderPrice,
        { ...price, priceId: price.generatedId, count: Factor },
      ];

      await EditDraftOrderApi.sendRequest({
        token: cookie.jwt,
        body: {
          id: draftId,
          lines: lines?.map((p) => ({
            SalesOrderNumber: '',
            priceId: p.generatedId,
            ItemNumber: p.ItemNumber,
            OrderedSalesQuantity: p.count,
            SalesUnitSymbol: p.QuantityUnitySymbol,
          })),
        },
      });
      setSelectedOrderPrice(
        lines.map((l) => ({
          ...l,
          units: price?.releasedProduct?.units,
          unitsOfMeasure: price?.releasedProduct?.unitsOfMeasure,
        })),
      );
    }
  };

  const minusProduct = async (price, Factor) => {
    if (selectedOrderPrice?.find((p) => p.id === price.id)) {
      const lines = selectedOrderPrice?.map((p) => {
        if (p.priceId == price.generatedId) {
          return {
            ...p,
            count: (p?.count || 0) - Factor > 0 ? (p?.count || 0) - Factor : 0,
          };
        } else {
          return p;
        }
      });
      await EditDraftOrderApi.sendRequest({
        token: cookie.jwt,
        body: {
          id: draftId,
          lines: lines?.map((p) => ({
            SalesOrderNumber: '',
            priceId: p.generatedId,
            ItemNumber: p.ItemNumber,
            OrderedSalesQuantity: p.count,
            SalesUnitSymbol: p.QuantityUnitySymbol,
          })),
        },
      });
      setSelectedOrderPrice(
        lines.map((l) => ({
          ...l,
          units: price?.releasedProduct?.units,
          unitsOfMeasure: price?.releasedProduct?.unitsOfMeasure,
        })),
      );
    } else {
      const lines = [
        ...selectedOrderPrice,
        { ...price, priceId: price.generatedId, count: 0 },
      ];
      await EditDraftOrderApi.sendRequest({
        token: cookie.jwt,
        body: {
          id: draftId,
          lines: lines?.map((p) => ({
            SalesOrderNumber: '',
            priceId: p.generatedId,
            ItemNumber: p.ItemNumber,
            OrderedSalesQuantity: p.count,
            SalesUnitSymbol: p.QuantityUnitySymbol,
          })),
        },
      });
      setSelectedOrderPrice(
        lines.map((l) => ({
          ...l,
          units: price?.releasedProduct?.units,
          unitsOfMeasure: price?.releasedProduct?.unitsOfMeasure,
        })),
      );
    }
  };

  useEffect(() => {
    try {
      if (draftId && cookie.jwt) {
        GetAddressesHandler();

        GetDraftByIdApi.sendRequest({ token: cookie.jwt, draftId }).then(
          (result) => {
            setOtherData({
              RequestedShippingDate: result?.RequestedShippingDate || null,
              DeliveryAddressLocationId: result?.DeliveryAddressLocationId,
              CustomerRequisitionNumber: result?.CustomerRequisitionNumber,
              DeliveryRequired: result?.DeliveryRequired,
              CustomersOrderReference: result?.CustomersOrderReference,
            });
            const mapIds = result?.lines?.map((l) => l.priceId);

            GetProductsHandler({});

            if (mapIds?.length) {
              GetProductsApi.sendRequest({
                id: mapIds,
                token: cookie.jwt,
              }).then((res) => {
                const r = res[0]?.map((i) => ({
                  ...i,
                  priceId: i?.generatedId,
                  count: +result?.lines?.find(
                    (rrr) => rrr.priceId == i?.generatedId,
                  )?.OrderedSalesQuantity,
                }));

                setSelectedOrderPrice(r);
              });
            }
            GetUnitsOfMeasureApi.sendRequest({ token: cookie.jwt }).then(
              (result) => {
                setUnitsOfMeasureState(result);
              },
            );
          },
        );
      }
    } catch (error) {}
  }, [draftId]);

  const GetCategoriesHandler = async ({
    top = 99999,
    skip = 0,
    search = categorySearch,
  }) => {
    const result = await GetCategoriesApi.sendRequest({
      token: cookie.jwt,
      top,
      skip,
      search,
    });
    setCategories(result);
  };

  const GetProductsHandler = async (
    f = filter,
    categories = filter?.categories,
  ) => {
    try {
      const result = await GetProductsApi.sendRequest({
        ...{ ...f, categories },
        token: cookie.jwt,
      });
      setPrices(result[0]);
      setPriceCount(result[1]);
      return result;
    } catch (error) {}
  };

  const GetAddressesHandler = async () => {
    const result = await GetAddressesApi.sendRequest({ token: cookie.jwt });
    setAddresses(result);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const CreateOrderToD365Handler = async () => {
    const result = await CreateOrderToD365Api.sendRequest({
      token: cookie.jwt,
      draftId,
    });
    if (result?.SalesOrderNumber) {
      dispatch(setAlert({ text: t('OrderSuccessCreated'), status: 200 }));
      navigate(`/orders/full/${result.SalesOrderNumber}`);
    } else {
      dispatch(setAlert({ text: t('OrderFailedCreated'), status: 500 }));
    }
  };

  const RemoveDraftOrderHandler = async () => {
    const result = await RemoveDraftOrderApi.sendRequest({
      token: cookie.jwt,
      draftId,
    });
    if (result) {
      dispatch(
        setAlert({ text: 'Черновик заказа успешно удален', status: 200 }),
      );
      navigate(`/orders/draft`);
    }
  };

  const toggledItemRef = React.useRef({});
  const apiRef = useTreeViewApiRef();

  const handleItemSelectionToggle = (event, itemId, isSelected) => {
    toggledItemRef.current[itemId] = isSelected;
  };

  const handleSelectedItemsChange = (event, newSelectedItems) => {
    setFilter({ ...filter, categories: newSelectedItems });

    // Select / unselect the children of the toggled item
    const itemsToSelect = [];
    const itemsToUnSelect = {};
    Object.entries(toggledItemRef.current).forEach(([itemId, isSelected]) => {
      const item = apiRef.current.getItem(itemId);
      if (isSelected) {
        itemsToSelect.push(...getItemDescendantsIds(item));
      } else {
        getItemDescendantsIds(item).forEach((descendantId) => {
          itemsToUnSelect[descendantId] = true;
        });
      }
    });

    const newSelectedItemsWithChildren = Array.from(
      new Set(
        [...newSelectedItems, ...itemsToSelect].filter(
          (itemId) => !itemsToUnSelect[itemId],
        ),
      ),
    );

    setFilter({ ...filter, categories: newSelectedItemsWithChildren });

    toggledItemRef.current = {};
  };

  return (
    <MainContainer needAuth={true}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography as="h1" variant="h4" sx={{ fontWeight: 'bold' }}>
            {t('CreateOrderTitle')}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} sx={{ pb: 2 }}>
          <Card variant="outlined" sx={{ mt: 2, mb: 3 }}>
            <CardContent>
              <TextField
                label={t('InternalOrderNumberField')}
                fullWidth
                disabled={
                  RemoveDraftOrderApi.loading || CreateOrderToD365Api.loading
                }
                value={otherOrderData?.CustomerRequisitionNumber}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={({ target: { value } }) => {
                  EditDraftOrderApi.sendRequest({
                    token: cookie.jwt,
                    body: {
                      id: draftId,
                      CustomerRequisitionNumber: value,
                    },
                  });
                  setOtherData({
                    ...otherOrderData,
                    CustomerRequisitionNumber: value,
                  });
                }}
              />
              <Stack direction="row" spacing={2} sx={{ mt: 3 }}>
                <FormControl fullWidth sx={{ mt: 4 }} required={true}>
                  <InputLabel id="demo-simple-select-label">
                    {t('DeliveryAddress')}
                  </InputLabel>
                  {!!addresses?.length && (
                    <Select
                      disabled={
                        RemoveDraftOrderApi.loading ||
                        CreateOrderToD365Api.loading
                      }
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={otherOrderData?.DeliveryAddressLocationId}
                      label={t('DeliveryAddress')}
                      error={!otherOrderData?.DeliveryAddressLocationId}
                      onChange={({ target: { value } }) => {
                        EditDraftOrderApi.sendRequest({
                          token: cookie.jwt,
                          body: {
                            id: draftId,
                            DeliveryAddressLocationId: value,
                          },
                        });
                        setOtherData({
                          ...otherOrderData,
                          DeliveryAddressLocationId: value,
                        });
                      }}
                    >
                      {addresses?.map((address) => (
                        <MenuItem
                          key={address?.AddressLocationId}
                          value={address?.AddressLocationId}
                        >
                          {address?.FormattedAddress}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                  {!otherOrderData?.DeliveryAddressLocationId && (
                    <FormHelperText sx={{ color: '#d32f2f' }}>
                      {t('SelectDeliveryAddressField')}
                    </FormHelperText>
                  )}
                  {!GetDraftByIdApi?.loading && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={otherOrderData.DeliveryRequired}
                          onChange={({ target: { checked } }) => {
                            EditDraftOrderApi.sendRequest({
                              token: cookie.jwt,
                              body: {
                                id: draftId,
                                DeliveryRequired: checked,
                              },
                            });
                            setOtherData({
                              ...otherOrderData,
                              DeliveryRequired: checked,
                            });
                          }}
                        />
                      }
                      label={t('DeliveryRequired')}
                    />
                  )}
                </FormControl>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={enGB}
                >
                  <DatePicker
                    minDate={add(new Date(), { days: 1 })}
                    label={t('DeliveryDateField')}
                    sx={{ width: '100%', mt: 3 }}
                    required={true}
                    disabled={
                      RemoveDraftOrderApi.loading ||
                      CreateOrderToD365Api.loading
                    }
                    slotProps={{
                      field: { clearable: true },
                    }}
                    value={otherOrderData?.RequestedShippingDate || null}
                    onChange={(value) => {
                      setDateWasChange(true);
                      EditDraftOrderApi.sendRequest({
                        token: cookie.jwt,
                        body: {
                          id: draftId,

                          RequestedShippingDate: value,
                        },
                      });

                      setOtherData({
                        ...otherOrderData,
                        RequestedShippingDate: value,
                      });
                    }}
                  />
                </LocalizationProvider>
              </Stack>
              <Divider sx={{ my: 2 }} />
              <Stack direction="row" spacing={2} alignItems="center">
                <TextField
                  label={t('OrderComment')}
                  fullWidth
                  error={commentError}
                  helperText={
                    commentError
                      ? t('commentError', { commentMaxLength })
                      : `(${
                          otherOrderData?.CustomersOrderReference?.length || 0
                        }/${commentMaxLength})`
                  }
                  value={otherOrderData.CustomersOrderReference}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={({ target: { value } }) => {
                    if (
                      otherOrderData?.CustomersOrderReference?.length <
                      commentMaxLength
                    ) {
                      EditDraftOrderApi.sendRequest({
                        token: cookie.jwt,
                        body: {
                          id: draftId,
                          CustomersOrderReference: value,
                        },
                      });
                      setCommentError(false);
                    } else {
                      setCommentError(true);
                    }
                    setOtherData({
                      ...otherOrderData,
                      CustomersOrderReference: value,
                    });
                  }}
                />
              </Stack>
            </CardContent>
          </Card>

          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
            sx={{ my: 2 }}
          >
            <Box>
              <Typography as="p" variant="h6" sx={{ mb: 0, pb: 0 }}>
                {t('ProductsInOrder')}
              </Typography>
            </Box>
            <Box>
              <Button
                color="success"
                variant="outlined"
                onClick={() => {
                  GetCategoriesHandler({});
                  setOpenSelectProductsModal(true);
                }}
                disabled={
                  CreateOrderToD365Api.loading || RemoveDraftOrderApi.loading
                }
              >
                {t('SelectProducts')}
              </Button>
            </Box>
          </Stack>

          {GetDraftByIdApi?.loading && GetProductsApi.loading ? (
            [1, 2, 3].map((i) => (
              <Skeleton
                variant="rounded"
                sx={{ width: '100%', mb: 1 }}
                height={86}
                key={i}
              />
            ))
          ) : selectedOrderPrice?.length ? (
            selectedOrderPrice?.map((price) => (
              <OrderProductItem
                showOtherData={true}
                price={price}
                changeItemCountField={(price, Factor) =>
                  changeItemCountField(price, Factor)
                }
                disabled={
                  CreateOrderToD365Api.loading || RemoveDraftOrderApi.loading
                }
                selectedOrderPrice={selectedOrderPrice}
                minusProduct={(data) =>
                  minusProduct(
                    data,
                    +price?.BoxOrPalletMeasure?.box?.Factor || 1,
                  )
                }
                sumProduct={(data) =>
                  sumProduct(data, +price?.BoxOrPalletMeasure?.box?.Factor || 1)
                }
                removeItem={(price) => removeItem(price)}
              />
            ))
          ) : (
            <Stack alignItems="center" sx={{ mt: 17 }} justifyContent="center">
              <Typography sx={{ opacity: 0.7 }}>
                {t('NoProductsInOrder')}
              </Typography>
            </Stack>
          )}
        </Grid>
        <Grid item xs={12} sm={6} sx={{ pb: 2 }}>
          <StickyContainer style={{ height: '100%' }}>
            <Sticky>
              {({ style, distanceFromTop }) => (
                <div style={style}>
                  <Card
                    sx={{ mt: Math.ceil(distanceFromTop) < 0 ? '70px' : 2 }}
                    variant="outlined"
                  >
                    <CardContent>
                      <Typography as="p" variant="h6">
                        {t('Total')}:
                      </Typography>
                      {!!selectedOrderPrice?.length && (
                        <>
                          <Stack
                            sx={{ py: 1, borderBottom: 'solid 1px #eee' }}
                            direction="row"
                            spacing={2}
                            justifyContent="space-between"
                          >
                            <Box>{t('NetWeight')}</Box>
                            <Box>
                              {
                                +selectedOrderPrice
                                  ?.map(
                                    (p) =>
                                      +BoxAndPalletReducer(
                                        p,
                                        i18n.getDataByLanguage(i18n.language),
                                      )?.NetProductWeightFull,
                                  )
                                  ?.reduce((a, b) => +a + +b)
                                  ?.toFixed(3)
                              }{' '}
                              {t('kg')}.
                            </Box>
                          </Stack>
                          <Stack
                            sx={{ py: 1, borderBottom: 'solid 1px #eee' }}
                            direction="row"
                            spacing={2}
                            justifyContent="space-between"
                          >
                            <Box>{t('GrossWeight')}</Box>
                            <Box>
                              {
                                +selectedOrderPrice
                                  ?.map(
                                    (p) =>
                                      +BoxAndPalletReducer(
                                        p,
                                        i18n.getDataByLanguage(i18n.language),
                                      )?.TareProductWeightFull,
                                  )
                                  ?.reduce((a, b) => +a + +b)
                                  ?.toFixed(3)
                              }{' '}
                              {t('kg')}.
                            </Box>
                          </Stack>

                          <Stack
                            sx={{ py: 1, borderBottom: 'solid 1px #eee' }}
                            direction="row"
                            spacing={2}
                            justifyContent="space-between"
                          >
                            <Box>{t('TotalBoxCount')}</Box>
                            <Box>
                              {
                                +selectedOrderPrice
                                  ?.map((p) => {
                                    const r = BoxAndPalletReducer(
                                      p,
                                      i18n.getDataByLanguage(i18n.language),
                                    );

                                    return +r.count / +r.BoxFactor;
                                  })
                                  ?.reduce((a, b) => +a + +b)
                                  ?.toFixed(3)
                              }{' '}
                            </Box>
                          </Stack>

                          <Stack
                            sx={{ py: 1, borderBottom: 'solid 1px #eee' }}
                            direction="row"
                            spacing={2}
                            justifyContent="space-between"
                          >
                            <Box>{t('TotalPalletCount')}</Box>
                            <Box>
                              {Math.ceil(
                                +selectedOrderPrice
                                  ?.map((p) => {
                                    const r = BoxAndPalletReducer(
                                      p,
                                      i18n.getDataByLanguage(i18n.language),
                                    );
                                    return r.PalletFactor
                                      ? +r.count / +r.PalletFactor
                                      : 0;
                                  })
                                  ?.reduce((a, b) => +a + +b)
                                  ?.toFixed(3),
                              )}{' '}
                              (
                              {
                                +selectedOrderPrice
                                  ?.map((p) => {
                                    const r = BoxAndPalletReducer(
                                      p,
                                      i18n.getDataByLanguage(i18n.language),
                                    );
                                    return r.PalletFactor
                                      ? +r.count / +r.PalletFactor
                                      : 0;
                                  })
                                  ?.reduce((a, b) => +a + +b)
                                  ?.toFixed(3)
                              }
                              ){' '}
                            </Box>
                          </Stack>
                          {+selectedOrderPrice
                            ?.map((p) => {
                              const r = BoxAndPalletReducer(
                                p,
                                i18n.getDataByLanguage(i18n.language),
                              );
                              return r.PalletFactor
                                ? +r.count / +r.PalletFactor
                                : 0;
                            })
                            ?.reduce((a, b) => +a + +b)
                            ?.toFixed(3) <
                            Math.ceil(
                              +selectedOrderPrice
                                ?.map((p) => {
                                  const r = BoxAndPalletReducer(
                                    p,
                                    i18n.getDataByLanguage(i18n.language),
                                  );
                                  return r.PalletFactor
                                    ? +r.count / +r.PalletFactor
                                    : 0;
                                })
                                ?.reduce((a, b) => +a + +b)
                                ?.toFixed(3),
                            ) && (
                            <Alert
                              severity="warning"
                              color="warning"
                              sx={{ my: 1 }}
                            >
                              {t('PalletCountWarning')}
                            </Alert>
                          )}
                          <Stack
                            sx={{ py: 1, borderBottom: 'solid 1px #eee' }}
                            direction="row"
                            spacing={2}
                            justifyContent="space-between"
                          >
                            <Box>{t('TotalItemsCount')}</Box>
                            <Box>
                              {
                                +selectedOrderPrice
                                  ?.map((p) => p.count)
                                  ?.reduce((a, b) => +a + +b)
                              }{' '}
                            </Box>
                          </Stack>

                          <Stack
                            sx={{ py: 1, borderBottom: 'solid 1px #eee' }}
                            direction="row"
                            spacing={2}
                            justifyContent="space-between"
                          >
                            <Box>{t('ProductsTabTitle')}</Box>
                            <Box>{+selectedOrderPrice.length}</Box>
                          </Stack>
                        </>
                      )}
                      <Stack
                        direction="row"
                        spacing={2}
                        justifyContent="space-between"
                        sx={{ py: 1, borderBottom: 'solid 1px #eee' }}
                      >
                        <Box>{t('InternalOrderNumber')}</Box>
                        <Box>
                          {otherOrderData.CustomerRequisitionNumber ||
                            t('NotSpecified')}
                        </Box>
                      </Stack>
                      <Stack
                        direction="row"
                        spacing={2}
                        justifyContent="space-between"
                        sx={{ py: 1, borderBottom: 'solid 1px #eee' }}
                      >
                        <Box>{t('DeliveryAddress')}</Box>
                        <Box>
                          {addresses?.find(
                            (a) =>
                              a?.AddressLocationId ==
                              otherOrderData?.DeliveryAddressLocationId,
                          )?.FormattedAddress || t('NotSpecified')}
                        </Box>
                      </Stack>
                      {!!Object.keys(otherOrderData)?.length && (
                        <Stack
                          direction="row"
                          spacing={2}
                          justifyContent="space-between"
                          sx={{ py: 1, borderBottom: 'solid 1px #eee' }}
                        >
                          <Box>{t('DeliveryDate')}</Box>

                          <Box>
                            {otherOrderData?.RequestedShippingDate &&
                            validator.isDate(
                              new Date(otherOrderData?.RequestedShippingDate),
                            )
                              ? format(
                                  otherOrderData?.RequestedShippingDate,
                                  'dd/MM/yyyy',
                                )
                              : !dateWasChange ||
                                !otherOrderData?.RequestedShippingDate ||
                                !validator.isDate(
                                  otherOrderData?.RequestedShippingDate,
                                )
                              ? t('NotSpecified')
                              : format(
                                  otherOrderData?.RequestedShippingDate,
                                  'dd/MM/yyyy',
                                )}
                          </Box>
                        </Stack>
                      )}

                      <Stack
                        direction="row"
                        spacing={2}
                        justifyContent="space-between"
                        sx={{ py: 1, borderBottom: 'solid 1px #eee' }}
                      >
                        <Box>{t('AmountWithoutTax')}</Box>

                        {selectedOrderPrice?.length && (
                          <Box>
                            {selectedOrderPrice
                              ?.map((item) => +item.Price * +item.count)
                              ?.reduce((a, b) => a + b)
                              .toFixed(2)}{' '}
                            {selectedOrderPrice[0].PriceCurrencyCode}
                          </Box>
                        )}
                      </Stack>
                    </CardContent>
                  </Card>

                  <Alert color="info" sx={{ mt: 2 }}>
                    <Typography>
                      <Typography sx={{ fontWeight: 'bold' }}>
                        {t('PriceClarification')}
                      </Typography>
                      <Typography>
                        {t('PriceClarificationDescription')}
                      </Typography>
                    </Typography>
                  </Alert>
                  <Card sx={{ mt: 2 }} variant="outlined">
                    <CardContent sx={{ paddingBottom: '16px !important' }}>
                      <Stack direction="row" spacing={2}>
                        <LoadingButton
                          color="error"
                          variant="outlined"
                          onClick={() => {
                            setOpenDeleteOrder(true);
                            // RemoveDraftOrderHandler();
                          }}
                          sx={{ width: '50%' }}
                          loading={
                            CreateOrderToD365Api.loading ||
                            RemoveDraftOrderApi.loading
                          }
                        >
                          {t('DeleteDraft')}
                        </LoadingButton>

                        <LoadingButton
                          color="success"
                          variant="contained"
                          onClick={() => {
                            setOpenConfirmOrder(true);
                          }}
                          sx={{ width: '50%' }}
                          disabled={
                            !otherOrderData?.RequestedShippingDate ||
                            differenceInDays(
                              new Date(),
                              new Date(otherOrderData?.RequestedShippingDate),
                            ) > 0 ||
                            !otherOrderData?.DeliveryAddressLocationId ||
                            !selectedOrderPrice?.length
                          }
                          loading={
                            CreateOrderToD365Api.loading ||
                            RemoveDraftOrderApi.loading
                          }
                        >
                          {t('ConfirmOrder')}
                        </LoadingButton>
                      </Stack>
                    </CardContent>
                  </Card>
                </div>
              )}
            </Sticky>
          </StickyContainer>
        </Grid>

        <Drawer
          anchor="bottom"
          open={openSelectProductsModal}
          onClose={() => setOpenSelectProductsModal(false)}
          sx={{ maxHeight: '100vh', overflow: 'hidden' }}
        >
          <Container maxWidth="xl">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{ mt: 2, mb: 3 }}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box>
                    <Typography
                      as="h2"
                      variant="h5"
                      sx={{ fontWeight: 'bold' }}
                    >
                      {t('SelectProductToOrders')}
                    </Typography>
                  </Box>
                  <Box>
                    <Button
                      color="success"
                      variant="outlined"
                      onClick={() => {
                        setOpenSelectProductsModal(false);
                        setFilter({ top: 16, skip: 0 });
                        GetProductsHandler({ top: 16, skip: 0 }, []);
                      }}
                    >
                      {t('Done')}
                    </Button>
                  </Box>
                </Stack>
              </Grid>
              {isDesktop && (
                <Grid item xs={3} sx={{ height: '100vh' }}>
                  <>
                    <Stack>
                      <TextField
                        label={t('SearchByNameAndSku')}
                        fullWidth
                        value={filter.search}
                        onChange={({ target: { value } }) =>
                          setFilter({ ...filter, search: value })
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />

                      <Box sx={{ mt: 3 }}>
                        <Typography as="p" variant="h6" sx={{ mb: 1 }}>
                          {t('Categories')}
                        </Typography>
                        <Stack
                          direction="row"
                          spacing={2}
                          sx={{ mb: 2 }}
                          alignItems="center"
                        >
                          <TextField
                            label={t('SearchByCategory')}
                            fullWidth
                            value={categorySearch}
                            onChange={({ target: { value } }) =>
                              setCategorySearch(value)
                            }
                          />

                          <Box>
                            <IconButton
                              onClick={() => {
                                setFilter({
                                  ...filter,
                                  categories: [],
                                });
                                GetCategoriesHandler({
                                  top: 100,
                                  skip: 0,
                                  search: categorySearch,
                                });
                              }}
                            >
                              <SearchOutlinedIcon />
                            </IconButton>
                          </Box>
                        </Stack>

                        {GetCategoriesApi.loading ? (
                          <CircularProgress />
                        ) : categories?.length ? (
                          <RichTreeView
                            checkboxSelection
                            items={categories}
                            multiSelect
                            apiRef={apiRef}
                            defaultExpandedItems={filter.categories}
                            selectedItems={filter.categories}
                            onSelectedItemsChange={handleSelectedItemsChange}
                            onItemSelectionToggle={handleItemSelectionToggle}
                          />
                        ) : (
                          <Typography>{t('NotFoundCategories')}</Typography>
                        )}
                      </Box>
                    </Stack>
                    <Button
                      color="success"
                      variant="contained"
                      sx={{ mt: 3 }}
                      fullWidth
                      onClick={() => {
                        GetProductsHandler(filter);
                        if (!isDesktop) {
                          setOpenMobileFilter(false);
                        }
                      }}
                    >
                      {t('Filter')}
                    </Button>
                  </>
                </Grid>
              )}
              {!isDesktop && (
                <Grid item xs={12}>
                  <Button
                    color="primary"
                    variant="outlined"
                    fullWidth
                    onClick={() => setOpenMobileFilter(true)}
                  >
                    {t('Filter')}
                  </Button>

                  <Drawer
                    anchor="bottom"
                    open={openMobileFilter}
                    onClose={() => setOpenMobileFilter(false)}
                    sx={{
                      maxHeight: '100vh',
                      minHeight: '100vh',
                      overflow: 'hidden',
                    }}
                  >
                    <Box sx={{ p: 2 }}>
                      <Stack
                        direction="row"
                        spacing={2}
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ width: '100%', mb: 3 }}
                      >
                        <Box>
                          <Typography
                            as="p"
                            variant="h5"
                            sx={{ fontWeight: 'bold' }}
                          >
                            {t('Filter')}
                          </Typography>
                        </Box>
                        <Box>
                          <Fab
                            color="error"
                            onClick={() => setOpenMobileFilter(false)}
                            size="small"
                          >
                            <CloseOutlinedIcon />
                          </Fab>
                        </Box>
                      </Stack>
                      <>
                        <Stack>
                          <TextField
                            label={t('SearchByNameAndSku')}
                            fullWidth
                            value={filter.search}
                            onChange={({ target: { value } }) =>
                              setFilter({ ...filter, search: value })
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />

                          <Box sx={{ mt: 3 }}>
                            <Typography as="p" variant="h6" sx={{ mb: 1 }}>
                              {t('Categories')}
                            </Typography>
                            <Stack
                              direction="row"
                              spacing={2}
                              sx={{ mb: 2 }}
                              alignItems="center"
                            >
                              <TextField
                                label={t('SearchByCategory')}
                                fullWidth
                                value={categorySearch}
                                onChange={({ target: { value } }) =>
                                  setCategorySearch(value)
                                }
                              />

                              <Box>
                                <IconButton
                                  onClick={() => {
                                    setFilter({
                                      ...filter,
                                      categories: [],
                                    });
                                    GetCategoriesHandler({
                                      top: 100,
                                      skip: 0,
                                      search: categorySearch,
                                    });
                                  }}
                                >
                                  <SearchOutlinedIcon />
                                </IconButton>
                              </Box>
                            </Stack>

                            {GetCategoriesApi.loading ? (
                              <CircularProgress />
                            ) : categories?.length ? (
                              <RichTreeView
                                checkboxSelection
                                items={categories}
                                multiSelect
                                apiRef={apiRef}
                                defaultExpandedItems={filter.categories}
                                selectedItems={filter.categories}
                                onSelectedItemsChange={
                                  handleSelectedItemsChange
                                }
                                onItemSelectionToggle={
                                  handleItemSelectionToggle
                                }
                              />
                            ) : (
                              <Typography>{t('NotFoundCategories')}</Typography>
                            )}
                          </Box>
                        </Stack>
                        <Button
                          color="success"
                          variant="contained"
                          sx={{ mt: 3 }}
                          fullWidth
                          onClick={() => {
                            GetProductsHandler(filter);
                            if (!isDesktop) {
                              setOpenMobileFilter(false);
                            }
                          }}
                        >
                          {t('Filter')}
                        </Button>
                      </>
                    </Box>
                  </Drawer>
                </Grid>
              )}
              <Grid item xs={12} lg={9}>
                {!GetProductsApi.loading ? (
                  prices?.length ? (
                    prices?.map((price) => (
                      <OrderProductItem
                        price={price}
                        selectedOrderPrice={selectedOrderPrice}
                        minusProduct={(data) =>
                          minusProduct(
                            data,
                            +price?.BoxOrPalletMeasure?.box?.Factor || 1,
                          )
                        }
                        sumProduct={(data) =>
                          sumProduct(
                            data,
                            +price?.BoxOrPalletMeasure?.box?.Factor || 1,
                          )
                        }
                        changeItemCountField={(price, Factor) =>
                          changeItemCountField(price, Factor)
                        }
                      />
                    ))
                  ) : (
                    <Typography>{t('ProductsNotFound')}</Typography>
                  )
                ) : (
                  [1, 2, 3, 4, 5, 6].map((i) => (
                    <Skeleton
                      variant="rounded"
                      sx={{ width: '100%', mb: 1 }}
                      height={84}
                    />
                  ))
                )}

                {Math.floor(priceCount / 16) > 1 && (
                  <Pagination
                    sx={{ mt: 3, mb: 5 }}
                    count={Math.floor(priceCount / 16)}
                    page={page}
                    onChange={(event, value) => {
                      setPage(value);
                      GetProductsHandler({
                        ...filter,
                        skip: 16 * value,
                        top: 16,
                      });
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </Container>
        </Drawer>
      </Grid>

      <Modal
        open={openConfirmOrder}
        onClose={() => setOpenConfirmOrder(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box
          sx={{
            width: '100%',
            maxWidth: '450px',
            background: '#fff',
            borderRadius: '5px',
            padding: '15px',
          }}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <ErrorOutlinedIcon color="warning" />
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {t('ConfirmOrderTitle')}
            </Typography>
          </Stack>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {t('ConfirmOrderDescription')}
          </Typography>
          {selectedOrderPrice?.some((s) => s.count == 0) && (
            <Alert color="warning" severity="warning">
              <Typography>{t('ZeroCountItemInOrdersWarning')}</Typography>
            </Alert>
          )}
          <Stack direction="row" spacing={2} sx={{ mt: 3 }}>
            <Button
              variant="contained"
              color="success"
              onClick={() => {
                CreateOrderToD365Handler();
                setOpenConfirmOrder(false);
              }}
            >
              {t('ConfirmOrderTitle')}
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={() => setOpenConfirmOrder(false)}
            >
              {t('Cancel')}
            </Button>
          </Stack>
        </Box>
      </Modal>

      <Modal
        open={openDeleteOrder}
        onClose={() => setOpenDeleteOrder(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box
          sx={{
            width: '100%',
            maxWidth: '450px',
            background: '#fff',
            borderRadius: '5px',
            padding: '15px',
          }}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <ErrorOutlinedIcon color="warning" />
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {t('ConfirmDeleteDraftTtile')}
            </Typography>
          </Stack>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {t('ConfirmDeleteDraftDescription')}
          </Typography>
          <Stack direction="row" spacing={2} sx={{ mt: 3 }}>
            <Button
              variant="contained"
              color="success"
              onClick={() => {
                RemoveDraftOrderHandler();
                setOpenDeleteOrder(false);
              }}
            >
              {t('ConfirmDeleteDraftButton')}
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={() => setOpenDeleteOrder(false)}
            >
              {t('Cancel')}
            </Button>
          </Stack>
        </Box>
      </Modal>
    </MainContainer>
  );
};
