import axios from 'axios';
import { BACKEND_URL } from '../utils/url';

export const Auth = async ({ email = null, password = null }, lang) => {
  const { data } = await axios.post(
    `${BACKEND_URL}/v1/auth/sign`,
    {
      email,
      password,
    },
    {
      headers: {
        language: lang,
      },
    },
  );
  return data;
};

export const GetMe = async ({ token }, lang) => {
  const { data } = await axios.get(`${BACKEND_URL}/v1/auth/me`, {
    headers: {
      Authorization: `Bearer ${token}`,
      language: lang,
    },
  });
  return data;
};
