import axios from 'axios';
import { BACKEND_URL } from '../utils/url';

export const GetSettings = async (lang) => {
  const { data } = await axios.get(`${BACKEND_URL}/v1/settings`, {
    headers: {
      language: lang,
    },
  });
  return data;
};
