import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ruLang from './i18n/ru.json';
import enLang from './i18n/en.json';
import plLang from './i18n/pl.json';
// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: enLang,
  },
  ru: {
    translation: ruLang,
  },
  pl: {
    translation: plLang,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });
