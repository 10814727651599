import { createSlice } from '@reduxjs/toolkit';

export const transactionSlice = createSlice({
  name: 'transactionsStore',
  initialState: [],
  reducers: {
    setTransactionsStore: (state, action) => {
      return action.payload;
    },
  },
});
export const { setTransactionsStore } = transactionSlice.actions;
export default transactionSlice.reducer;
