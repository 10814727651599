import { Box, Card, CardContent, Chip, Stack, Typography } from '@mui/material';
import { differenceInCalendarDays, format } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const InvoiceItem = ({
  invoice,
  isNoPayment = false,
  isNearPayment = false,
  transaction,
  check,
}) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <Card
      onClick={() => navigate(`/invoices?InvoiceId=${invoice?.InvoiceId}`)}
      sx={{ height: '119px', maxHeight: '119px' }}
    >
      <CardContent>
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <Box>
            <Typography as="p" variant="p" sx={{ fontWeight: 'bold' }}>
              {invoice?.InvoiceId}
            </Typography>
          </Box>
          <Box>
            {check && isNearPayment && (
              <Chip
                label={
                  t('nearPaymentStatus') +
                  ' - ' +
                  ' ' +
                  differenceInCalendarDays(
                    new Date(invoice?.DueDate),
                    new Date(),
                  ) +
                  t('days')
                }
                size="small"
                color="warning"
              />
            )}
            {check && isNoPayment && (
              <Chip
                label={
                  t('noPaymentStatus') +
                  ' ' +
                  t('on') +
                  ' ' +
                  differenceInCalendarDays(
                    new Date(),
                    new Date(invoice?.DueDate),
                  ) +
                  t('days')
                }
                size="small"
                color="error"
              />
            )}
            {check && transaction && !isNoPayment && !isNearPayment && (
              <Chip
                label={
                  t('PaymentPeriod') +
                  ' ' +
                  differenceInCalendarDays(
                    new Date(invoice?.DueDate),
                    new Date(),
                  ) +
                  t('days')
                }
                size="small"
                color="success"
              />
            )}
          </Box>
        </Stack>
        <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
          <Box>
            <Typography as="p" variant="caption">
              {t('Created')}: {format(invoice?.InvoiceDate, 'dd.MM.yyyy')}
            </Typography>
            <Typography as="p" variant="caption">
              {t('DueDate')}: {format(invoice?.DueDate, 'dd.MM.yyyy')}
            </Typography>
          </Box>
          <Box>
            <Typography as="p" variant="caption">
              {t('Amount')}: {invoice?.InvoiceAmount} {invoice?.CurrencyCode}
            </Typography>
            {transaction && (
              <Typography as="p" variant="caption">
                {t('ForPayment')}:{' '}
                {(
                  transaction?.AmountCur - transaction?.SettleAmountCur
                ).toFixed(2)}{' '}
                {invoice?.CurrencyCode}
              </Typography>
            )}
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};
