import React, { useEffect, useState } from 'react';
import { MainContainer } from '../components/Default/Layout/MainContainer';
import { useParams } from 'react-router-dom';
import { useApi } from '../hooks/useApi';
import {
  GetMySingleOrder,
  GetOrderInvoices,
  GetOrderLines,
} from '../api/OrderApi';
import { useCookies } from 'react-cookie';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NoPhotographyOutlinedIcon from '@mui/icons-material/NoPhotographyOutlined';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  Skeleton,
  Stack,
  Tab,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';

import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useTranslation } from 'react-i18next';
import { OrderItem } from '../components/Order/OrderItem';
import { OrderProductItem } from '../components/Order/OrderProductItem';
import { useResponsive } from '../hooks/useResponsive';

export const SingleOrderPage = () => {
  const { isMobile, isTablet, isDesktop } = useResponsive();
  const { t, i18n } = useTranslation();
  const { SalesOrderNumber } = useParams();
  const GetMySingleOrderApi = useApi(GetMySingleOrder);
  const GetOrderLinesApi = useApi(GetOrderLines);
  const GetOrderInvoicesApi = useApi(GetOrderInvoices);
  const [order, setOrder] = useState({});
  const [invoices, setInvoices] = useState([]);
  const [orderLines, setOrderLines] = useState([]);
  const [cookie] = useCookies('jwt');

  const [tab, setTab] = React.useState('1');

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  const GetSingleOrderHandler = async () => {
    const result = await GetMySingleOrderApi.sendRequest({
      token: cookie.jwt,
      SalesOrderNumber,
    });
    setOrder(result);
  };

  const GetOrderLinesHandler = async () => {
    const result = await GetOrderLinesApi.sendRequest({
      token: cookie.jwt,
      SalesOrderNumber,
    });
    setOrderLines(result);
  };

  const GetOrderInvoicesHandler = async () => {
    const result = await GetOrderInvoicesApi.sendRequest({
      token: cookie.jwt,
      SalesOrderNumber,
    });
    setInvoices(result);
  };

  useEffect(() => {
    if (cookie.jwt) {
      GetSingleOrderHandler();
      GetOrderLinesHandler();
      GetOrderInvoicesHandler();
    }
  }, []);
  return (
    <MainContainer needAuth={true}>
      {isDesktop && (
        <Box
          sx={{
            width: 'calc(50% + 8px)',
            height: '100vh',
            position: 'fixed',
            top: 0,
            right: 0,
            background: '#fff',
            zIndex: -1,
            borderLeft: 'solid 1px #0000001f',
          }}
        ></Box>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
            sx={{ mb: 2 }}
          >
            <Box sx={{ width: '100%' }}>
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                justifyContent="space-between"
                sx={{ width: '100%' }}
              >
                <Stack direction="row" spacing={2} alignItems="center">
                  {order?.SalesOrderNumber ? (
                    <Typography
                      as="h1"
                      variant="h5"
                      sx={{ fontWeight: 'bold' }}
                    >
                      {order?.SalesOrderNumber}
                    </Typography>
                  ) : (
                    <Skeleton variant="rounded" width={158} height={32} />
                  )}

                  {order?.SalesOrderNumber ? (
                    <Typography variant="p" sx={{ fontWeight: 'bold' }}>
                      {t('from')}{' '}
                      {order?.OrderCreationDateTime &&
                        format(order?.OrderCreationDateTime, 'dd.MM.yyyy')}
                    </Typography>
                  ) : (
                    <Skeleton variant="rounded" width={105} height={19} />
                  )}
                </Stack>

                <Box sx={{ pr: 2 }}>
                  {GetMySingleOrderApi?.loading ? (
                    <Skeleton variant="rounded" width={105} height={19} />
                  ) : order?.CustomerRequisitionNumber ? (
                    <Typography
                      variant="p"
                      sx={{ fontWeight: 'bold', ml: 'auto' }}
                    >
                      {order?.CustomerRequisitionNumber}
                    </Typography>
                  ) : (
                    <Box></Box>
                  )}
                </Box>
              </Stack>
            </Box>
          </Stack>
          <Divider />
          <Typography as="h1" variant="h6" sx={{ fontWeight: 'normal', my: 2 }}>
            {t('OrderTitle')}
          </Typography>

          <>
            {order?.SalesOrderNumber ? (
              <Stack
                direction="row"
                spacing={2}
                justifyContent="space-between"
                alignItems="center"
                sx={{ py: 1, borderBottom: 'solid 1px #ddd' }}
              >
                <Box>{t('RequestShippingDate')}</Box>
                <Box sx={{ pr: 2 }}>
                  {order?.RequestedShippingDate &&
                  order?.RequestedShippingDate == '1900-01-01T12:00:00Z'
                    ? ''
                    : format(order?.RequestedShippingDate, 'dd.MM.yyyy')}
                </Box>
              </Stack>
            ) : (
              <Skeleton
                variant="rounded"
                sx={{ width: '100%', my: 1 }}
                height={19}
              />
            )}
            {order?.SalesOrderNumber ? (
              <Stack
                direction="row"
                spacing={2}
                justifyContent="space-between"
                alignItems="center"
                sx={{ py: 1, borderBottom: 'solid 1px #ddd' }}
              >
                <Box>{t('ConfirmedShippingDate')}</Box>

                <Box sx={{ pr: 2 }}>
                  {order?.ConfirmedShippingDate &&
                  order?.ConfirmedShippingDate == '1900-01-01T12:00:00Z'
                    ? ''
                    : format(order?.ConfirmedShippingDate, 'dd.MM.yyyy')}
                </Box>
              </Stack>
            ) : (
              <Skeleton
                variant="rounded"
                sx={{ width: '100%', my: 1 }}
                height={19}
              />
            )}
            {order?.SalesOrderNumber ? (
              <Stack
                direction="row"
                spacing={2}
                justifyContent="space-between"
                alignItems="center"
                sx={{ py: 1, borderBottom: 'solid 1px #ddd' }}
              >
                <Box>{t('ShippingAddess')}</Box>
                <Box sx={{ textAlign: 'right', pr: 2 }}>
                  {order?.FormattedDelveryAddress}
                </Box>
              </Stack>
            ) : (
              <Skeleton
                variant="rounded"
                sx={{ width: '100%', my: 1 }}
                height={19}
              />
            )}
            {order?.SalesOrderNumber ? (
              <Stack
                direction="row"
                spacing={2}
                justifyContent="space-between"
                alignItems="center"
                sx={{ py: 1, borderBottom: 'solid 1px #ddd' }}
              >
                <Box>{t('OrderCurrency')}</Box>
                <Box sx={{ pr: 2 }}>{order?.CurrencyCode}</Box>
              </Stack>
            ) : (
              <Skeleton
                variant="rounded"
                sx={{ width: '100%', my: 1 }}
                height={19}
              />
            )}
            {order?.SalesOrderNumber ? (
              <Stack
                direction="row"
                spacing={2}
                justifyContent="space-between"
                alignItems="center"
                sx={{ py: 1, borderBottom: 'solid 1px #ddd' }}
              >
                <Box>{t('SalesOrderStatus')}</Box>
                <Box sx={{ pr: 2 }}>{t(order?.SalesOrderStatus)}</Box>
              </Stack>
            ) : (
              <Skeleton
                variant="rounded"
                sx={{ width: '100%', my: 1 }}
                height={19}
              />
            )}
            {order?.SalesOrderNumber ? (
              <Stack
                direction="row"
                spacing={2}
                justifyContent="space-between"
                alignItems="center"
                sx={{ py: 1, borderBottom: 'solid 1px #ddd' }}
              >
                <Box>{t('SalesOrderProcessingStatus')}</Box>
                <Box sx={{ pr: 2 }}>{t(order?.SalesOrderProcessingStatus)}</Box>
              </Stack>
            ) : (
              <Skeleton
                variant="rounded"
                sx={{ width: '100%', my: 1 }}
                height={19}
              />
            )}

            <Box sx={{ pr: 2, mt: 5 }}>
              {GetOrderLinesApi?.loading ? (
                <Skeleton
                  variant="rounded"
                  sx={{ width: '100%' }}
                  height={64}
                />
              ) : orderLines?.length ? (
                <Card>
                  <CardContent sx={{ pb: 0 }}>
                    <Stack direction={isDesktop ? 'row' : 'column'} spacing={1}>
                      <Stack direction="row" spacing={1}>
                        <Typography>{t('TotalProductsCount')}: </Typography>
                        <Typography sx={{ fontWeight: 'bold' }}>
                          {orderLines?.length}.
                        </Typography>
                      </Stack>
                      <Stack direction="row" spacing={1}>
                        <Typography>
                          {t('InvoiceTotalSumWithoutTax')}:{' '}
                        </Typography>
                        {!!orderLines?.length && (
                          <Typography sx={{ fontWeight: 'bold' }}>
                            {orderLines
                              ?.map((ol) => ol?.LineAmount)
                              ?.reduce((a, b) => a + b)
                              ?.toFixed(2)}{' '}
                            {order?.CurrencyCode}
                          </Typography>
                        )}
                      </Stack>
                    </Stack>
                  </CardContent>
                </Card>
              ) : null}
            </Box>
          </>
        </Grid>
        <Grid item xs={12} lg={6}>
          <TabContext value={tab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                onChange={handleChangeTab}
                aria-label="lab API tabs example"
              >
                <Tab label={t('ProductsTabTitle')} value="1" />
                <Tab label={t('InvoicesTabTitle')} value="2" />
              </TabList>
            </Box>
            <TabPanel
              value="1"
              sx={{
                maxHeight: isDesktop ? '70vh' : 'auto',
                px: 0,
                overflow: 'auto',
              }}
            >
              {GetOrderLinesApi?.loading
                ? [1, 2, 3, 4, 5].map((i) => (
                    <Stack
                      direction="row"
                      spacing={2}
                      sx={{ py: 2, borderBottom: 'solid 1px #eee' }}
                      justifyContent="space-between"
                    >
                      <Stack direction="row" spacing={2}>
                        <Skeleton variant="rounded" width={13} height={32} />
                        <Box>
                          <Skeleton
                            variant="rounded"
                            width={300}
                            height={24}
                            sx={{ mb: 1 }}
                          />
                          <Stack direction="row" spacing={1}>
                            <Skeleton
                              variant="rounded"
                              width={64}
                              height={24}
                              sx={{ mr: 1 }}
                            />
                            <Skeleton
                              variant="rounded"
                              width={64}
                              height={24}
                            />
                          </Stack>
                        </Box>
                      </Stack>
                      <Box sx={{ textAlign: 'right', minWidth: '120px' }}>
                        <Skeleton
                          variant="rounded"
                          width={120}
                          height={24}
                          sx={{ mb: 1 }}
                        />
                        <Skeleton variant="rounded" width={120} height={19} />
                      </Box>
                    </Stack>
                  ))
                : orderLines?.length
                ? orderLines?.map((ol, index) =>
                    ol?.priceItem ? (
                      <OrderProductItem
                        price={{
                          ...ol.priceItem,
                          count: ol.OrderedSalesQuantity,
                        }}
                        showOtherData={true}
                        showCounts={false}
                        SalesOrderLineStatus={ol?.SalesOrderLineStatus}
                      />
                    ) : (
                      <Card variant="outlined" sx={{ mb: 1 }}>
                        <CardContent>
                          <Stack
                            direction="row"
                            spacing={2}
                            justifyContent="space-between"
                          >
                            <Stack direction="row" spacing={2}>
                              <Box
                                sx={{
                                  width: '60px',
                                  minWidth: '60px',
                                  height: '60px',
                                  borderRadius: '5px',
                                  background: '#f1f1f1',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  cursor: 'pointer',
                                }}
                              >
                                <NoPhotographyOutlinedIcon
                                  sx={{ opacity: '0.5' }}
                                />
                              </Box>
                              <Box>
                                <Typography>
                                  <span style={{ fontWeight: 'bold' }}>
                                    {ol?.ItemNumber}
                                  </span>{' '}
                                  {ol?.LineDescription}{' '}
                                </Typography>
                                <Stack direction="row" spacing={1}>
                                  <Chip
                                    label={t(ol?.SalesOrderLineStatus)}
                                    size="small"
                                  />
                                </Stack>
                              </Box>
                            </Stack>
                            {isDesktop && (
                              <Box
                                sx={{ textAlign: 'right', minWidth: '120px' }}
                              >
                                <Typography as="p">
                                  {ol?.LineAmount} {order?.CurrencyCode}
                                </Typography>
                                <Typography as="p" variant="caption">
                                  {ol?.SalesPrice} {order?.CurrencyCode} x{' '}
                                  {ol?.OrderedSalesQuantity}{' '}
                                  {t(ol?.SalesUnitSymbolTranslate)}.
                                </Typography>
                              </Box>
                            )}
                          </Stack>
                          {isMobile && (
                            <>
                              <Divider sx={{ my: 1 }} />
                              <Box
                                sx={{ textAlign: 'left', minWidth: '120px' }}
                              >
                                <Typography as="p">
                                  {ol?.LineAmount} {order?.CurrencyCode}
                                </Typography>
                                <Typography as="p" variant="caption">
                                  {ol?.OrderedSalesQuantity}{' '}
                                  {t(ol?.SalesUnitSymbolTranslate)}. x{' '}
                                  {ol?.SalesPrice} {order?.CurrencyCode}
                                </Typography>
                              </Box>
                            </>
                          )}
                        </CardContent>
                      </Card>
                    ),
                  )
                : null}
            </TabPanel>
            <TabPanel
              value="2"
              sx={{
                px: 0,
                maxHeight: isDesktop ? '70vh' : 'auto',
                overflow: 'auto',
              }}
            >
              {GetOrderInvoicesApi?.loading
                ? [1, 2, 3, 4, 5].map((i) => (
                    <Skeleton
                      variant="rounded"
                      sx={{ width: '100%', mb: 1 }}
                      height={58}
                    />
                  ))
                : invoices?.length
                ? invoices?.map((invoice) => (
                    <Accordion variant="outlined">
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel1-content-${invoice.InvoiceId}`}
                        id={`panel1-header-${invoice.InvoiceId}`}
                      >
                        <Stack
                          direction="row"
                          spacing={2}
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Box>
                            <Typography as="p" variant="h6">
                              {invoice?.InvoiceId}
                            </Typography>
                          </Box>
                          <Box>
                            {invoice?.InvoiceId && (
                              <Typography as="p" variant="p">
                                {t('from')}{' '}
                                {format(invoice?.InvoiceDate, 'dd.MM.yyyy')}
                              </Typography>
                            )}
                          </Box>
                        </Stack>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Stack
                          direction="row"
                          spacing={2}
                          alignItems="center"
                          justifyContent="space-between"
                          sx={{ py: 2, borderBottom: 'solid 1px #eee' }}
                        >
                          <Box>
                            <Typography>{t('InvoiceTotalSum')}</Typography>
                          </Box>
                          <Box sx={{ textAlign: 'center' }}>
                            <Typography>
                              {invoice?.InvoiceAmount} {invoice?.CurrencyCode}
                            </Typography>
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          spacing={2}
                          alignItems="center"
                          justifyContent="space-between"
                          sx={{ py: 2, borderBottom: 'solid 1px #eee' }}
                        >
                          <Box>
                            <Typography>
                              {t('InvoiceTotalSumWithoutTax')}
                            </Typography>
                          </Box>
                          <Box sx={{ textAlign: 'center' }}>
                            <Typography>
                              {invoice?.SalesBalance} {invoice?.CurrencyCode}
                            </Typography>
                          </Box>
                        </Stack>

                        <Stack
                          direction="row"
                          spacing={2}
                          alignItems="center"
                          justifyContent="space-between"
                          sx={{ py: 2, borderBottom: 'solid 1px #eee' }}
                        >
                          <Box>
                            <Typography>{t('InvoiceTaxSum')}</Typography>
                          </Box>

                          <Box sx={{ textAlign: 'center' }}>
                            <Typography>
                              {invoice?.SumTax} {invoice?.CurrencyCode}
                            </Typography>
                          </Box>
                        </Stack>

                        <Stack
                          direction="row"
                          spacing={2}
                          alignItems="center"
                          justifyContent="space-between"
                          sx={{ py: 2, borderBottom: 'solid 1px #eee' }}
                        >
                          <Box>
                            <Typography>{t('InvoiceDueDate')}</Typography>
                          </Box>
                          <Box sx={{ textAlign: 'center' }}>
                            <Typography>
                              {invoice?.InvoiceId
                                ? invoice?.DueDate != '1900-01-01T12:00:00Z'
                                  ? format(invoice?.DueDate, 'dd.MM.yyyy')
                                  : ''
                                : ''}
                            </Typography>
                          </Box>
                        </Stack>
                        <Typography
                          sx={{ mt: 2, fontWeight: 'bold' }}
                          as="p"
                          variant="p"
                        >
                          {t('ProductsTabTitle')}
                        </Typography>
                        {invoice?.Lines?.map((line, index) => (
                          <Stack
                            direction="row"
                            spacing={1}
                            justifyContent="space-between"
                            sx={{ py: 1 }}
                          >
                            <Stack
                              direction="row"
                              spacing={2}
                              sx={{ width: '65%' }}
                            >
                              <Box sx={{ width: '30px' }}>
                                <Typography
                                  as="p"
                                  variant="h5"
                                  sx={{ fontWeight: 'bold', opacity: 0.2 }}
                                >
                                  {index + 1}
                                </Typography>
                              </Box>
                              <Box sx={{ width: '100%' }}>
                                <Typography>
                                  <span style={{ fontWeight: 'bold' }}>
                                    {line?.ItemId}
                                  </span>{' '}
                                  {line?.Name}
                                </Typography>
                              </Box>
                            </Stack>

                            <Box sx={{ textAlign: 'right' }}>
                              <Typography as="p">
                                {line?.LineAmount} {invoice?.CurrencyCode}
                              </Typography>
                              <Typography as="p" variant="caption">
                                {line?.Qty} {t(line?.SalesUnitSymbolTranslate)}.
                                x {line?.SalesPrice} {invoice?.CurrencyCode}
                              </Typography>
                            </Box>
                          </Stack>
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  ))
                : null}
            </TabPanel>
          </TabContext>
        </Grid>
      </Grid>
    </MainContainer>
  );
};
