import React, { useEffect, useState } from 'react';
import { MainContainer } from '../components/Default/Layout/MainContainer';
import {
  Box,
  Button,
  Grid,
  Skeleton,
  Stack,
  Tab,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { useApi } from '../hooks/useApi';
import { GetInvoices } from '../api/OrderApi';
import { useCookies } from 'react-cookie';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import { InvoiceItem } from '../components/Invoice/InvoiceItem';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import { useTranslation } from 'react-i18next';

import { InvoicesTable } from '../components/Invoice/InvoicesTable';
import { useSelector } from 'react-redux';
import { useResponsive } from '../hooks/useResponsive';
import { useSearchParams } from 'react-router-dom';
import { SingleInvoicePage } from './SingleInvoicePage';

export const InvoicesListPages = () => {
  const { isMobile, isTablet, isDesktop } = useResponsive();
  const { t } = useTranslation();
  const [cookie, setCookie] = useCookies(['jwt', 'lang', 'invoiceView']);
  const [orderView, setOrderView] = useState(cookie.invoiceView || 'cards');
  const GetInvoicesApi = useApi(GetInvoices);
  const [searchParams, setSearchParams] = useSearchParams();
  const [skip, setSkip] = useState(0);
  const [invoices, setInvoices] = useState([]);
  const [tab, setTab] = useState(0);
  const [check, setCheck] = useState({});
  const [searchInvoice, setSearchInvoice] = useState('');
  const transactionsStore = useSelector((state) => state.transactionsStore);
  const GetInvoicesHandler = async ({
    s = skip,
    top = 16,
    search = searchInvoice,
  }) => {
    const result = await GetInvoicesApi.sendRequest({
      token: cookie.jwt,
      skip: s,
      top,
      search,
    });

    setInvoices(result);
  };

  useEffect(() => {
    if (cookie.jwt) {
      GetInvoicesHandler({ s: skip, top: 16 });
    }
  }, [skip]);

  return (
    <>
      {!searchParams.get('InvoiceId') ? (
        <MainContainer
          needAuth={true}
          setCheckhandler={(data) => setCheck(data)}
        >
          <TabContext value={tab}>
            <Grid container spacing={2} sx={{ pb: 5 }}>
              <Grid item xs={12}>
                <Stack
                  direction={isDesktop ? 'row' : 'column'}
                  spacing={2}
                  justifyContent="space-between"
                  alignItems={isDesktop ? 'center' : 'start'}
                >
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ width: isMobile ? '100%' : 'auto' }}
                  >
                    <Box>
                      <Typography
                        as="h1"
                        variant="h4"
                        sx={{ mb: 2, fontWeight: 'bold' }}
                      >
                        {t('Invoices')}
                      </Typography>
                    </Box>
                    {isMobile && (
                      <Box sx={{ pr: 3, ml: 'auto' }}>
                        <ToggleButtonGroup
                          value={orderView}
                          exclusive
                          onChange={(event, newAlignment) => {
                            setOrderView(newAlignment);
                            setCookie('invoiceView', newAlignment, {
                              path: '/',
                            });
                          }}
                          aria-label="text alignment"
                        >
                          <ToggleButton
                            value="table"
                            aria-label="left aligned"
                            size="small"
                          >
                            <ListOutlinedIcon />
                          </ToggleButton>
                          <ToggleButton
                            value="cards"
                            aria-label="centered"
                            size="small"
                          >
                            <AppsOutlinedIcon />
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </Box>
                    )}
                  </Stack>
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: 'divider',
                    }}
                  >
                    <TabList
                      value={tab}
                      onChange={(event, newValue) => setTab(newValue)}
                      aria-label="basic tabs example"
                    >
                      <Tab
                        value={0}
                        label={
                          isDesktop ? t('AllInvoices') : t('AllInvoicesShort')
                        }
                      />
                      <Tab
                        value={1}
                        label={
                          isDesktop
                            ? t('UnPaidInvoices')
                            : t('UnPaidInvoicesShort')
                        }
                      />
                      <Tab
                        value={2}
                        label={
                          isDesktop
                            ? t('PaymentCommingSoon')
                            : t('PaymentCommingSoonShort')
                        }
                      />
                    </TabList>
                  </Box>
                  {isDesktop && (
                    <Box sx={{ pr: 3 }}>
                      <ToggleButtonGroup
                        value={orderView}
                        exclusive
                        onChange={(event, newAlignment) => {
                          setOrderView(newAlignment);
                          setCookie('invoiceView', newAlignment, { path: '/' });
                        }}
                        aria-label="text alignment"
                      >
                        <ToggleButton
                          value="table"
                          aria-label="left aligned"
                          size="small"
                        >
                          <ListOutlinedIcon />
                        </ToggleButton>
                        <ToggleButton
                          value="cards"
                          aria-label="centered"
                          size="small"
                        >
                          <AppsOutlinedIcon />
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Box>
                  )}
                </Stack>
              </Grid>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    sx={{ px: 3 }}
                  >
                    <TextField
                      label={t('SearchInvoices')}
                      fullWidth
                      size="small"
                      value={searchInvoice}
                      onChange={({ target: { value } }) =>
                        setSearchInvoice(value)
                      }
                    />
                    <Button
                      color="success"
                      variant="outlined"
                      onClick={() =>
                        GetInvoicesHandler({
                          s: 0,
                          top: searchInvoice ? 9999 : 16,
                        })
                      }
                    >
                      {t('Search')}
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
              <TabPanel value={0} sx={{ width: '100%' }}>
                <Grid container spacing={2}>
                  {orderView === 'table' ? (
                    <Grid item xs={12}>
                      <InvoicesTable
                        transactions={transactionsStore}
                        invoices={invoices}
                        loading={GetInvoicesApi.loading}
                        check={check}
                      />
                    </Grid>
                  ) : !GetInvoicesApi.loading ? (
                    invoices?.length ? (
                      invoices?.map((invoice) => (
                        <Grid item lg={3} sm={6} xs={12}>
                          <InvoiceItem
                            transaction={transactionsStore?.All?.find(
                              (t) =>
                                t.TransDate == invoice.InvoiceDate &&
                                t.Voucher == invoice.LedgerVoucher,
                            )}
                            invoice={invoice}
                            check={check}
                            isNoPayment={check?.noPayment?.some(
                              (inv) => inv.InvoiceId == invoice.InvoiceId,
                            )}
                            isNearPayment={check?.nearPayment?.some(
                              (inv) => inv.InvoiceId == invoice.InvoiceId,
                            )}
                          />
                        </Grid>
                      ))
                    ) : (
                      <Grid item xs={12}>
                        <Typography>{t('InvoicesNotFound')}</Typography>
                      </Grid>
                    )
                  ) : (
                    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map(
                      (skeleton) => (
                        <Grid item lg={3} sm={6} xs={12}>
                          <Skeleton
                            variant="rounded"
                            sx={{ width: '100%' }}
                            height={118.91}
                          />
                        </Grid>
                      ),
                    )
                  )}

                  <Grid item xs={12} sx={{ pb: 3 }}>
                    <Stack direction="row" spacing={2}>
                      {skip > 0 && (
                        <Button
                          variant="outlined"
                          onClick={() => setSkip(skip - 16)}
                        >
                          {t('Back')}
                        </Button>
                      )}
                      {!!invoices?.length && invoices?.length == 16 && (
                        <Button
                          variant="outlined"
                          onClick={() => setSkip(skip + 16)}
                        >
                          {t('Continue')}
                        </Button>
                      )}
                    </Stack>
                  </Grid>
                </Grid>
              </TabPanel>

              <TabPanel value={1} sx={{ width: '100%' }}>
                <Grid container spacing={2}>
                  {orderView === 'table' ? (
                    <Grid item xs={12}>
                      <InvoicesTable
                        invoices={check?.noPayment}
                        transactions={transactionsStore}
                        loading={GetInvoicesApi.loading}
                        check={check}
                      />
                    </Grid>
                  ) : !GetInvoicesApi.loading ? (
                    check?.noPayment?.length ? (
                      check?.noPayment?.map((invoice) => (
                        <Grid item lg={3} sm={6} xs={12}>
                          <InvoiceItem
                            invoice={invoice}
                            isNearPayment={false}
                            isNoPayment={true}
                            transaction={transactionsStore?.All?.find(
                              (t) =>
                                t.TransDate == invoice.InvoiceDate &&
                                t.Voucher == invoice.LedgerVoucher,
                            )}
                            check={check}
                          />
                        </Grid>
                      ))
                    ) : (
                      <Grid item xs={12}>
                        <Typography>{t('InvoicesNotFound')}</Typography>
                      </Grid>
                    )
                  ) : (
                    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map(
                      (skeleton) => (
                        <Grid item xs={3}>
                          <Skeleton
                            variant="rounded"
                            sx={{ width: '100%' }}
                            height={118.91}
                          />
                        </Grid>
                      ),
                    )
                  )}
                </Grid>
              </TabPanel>

              <TabPanel value={2} sx={{ width: '100%' }}>
                <Grid container spacing={2}>
                  {orderView === 'table' ? (
                    <Grid item xs={12}>
                      <InvoicesTable
                        invoices={check?.nearPayment}
                        loading={GetInvoicesApi.loading}
                        transactions={transactionsStore}
                        check={check}
                      />
                    </Grid>
                  ) : !GetInvoicesApi.loading ? (
                    check?.nearPayment?.length ? (
                      check?.nearPayment?.map((invoice) => (
                        <Grid item lg={3} sm={6} xs={12}>
                          <InvoiceItem
                            invoice={invoice}
                            isNearPayment={true}
                            isNoPayment={false}
                            transaction={transactionsStore?.All?.find(
                              (t) =>
                                t.TransDate == invoice.InvoiceDate &&
                                t.Voucher == invoice.LedgerVoucher,
                            )}
                            check={check}
                          />
                        </Grid>
                      ))
                    ) : (
                      <Grid item xs={12}>
                        <Typography>{t('InvoicesNotFound')}</Typography>
                      </Grid>
                    )
                  ) : (
                    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map(
                      (skeleton) => (
                        <Grid item xs={3}>
                          <Skeleton
                            variant="rounded"
                            sx={{ width: '100%' }}
                            height={118.91}
                          />
                        </Grid>
                      ),
                    )
                  )}
                </Grid>
              </TabPanel>
            </Grid>
          </TabContext>
        </MainContainer>
      ) : (
        <SingleInvoicePage />
      )}
    </>
  );
};
