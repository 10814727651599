import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import './App.css';
import { ThemeProvider, createTheme } from '@mui/material';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { url } from './utils/url';
import { HomePage } from './pages/HomePage';
import { LoginPage } from './pages/LoginPage';
import { OrderListPage } from './pages/OrderListPage';
import { SingleOrderPage } from './pages/SingleOrderPage';
import { CreateOrder } from './pages/CreateOrder';
import { InvoicesListPages } from './pages/InvoicesListPages';
import { SingleInvoicePage } from './pages/SingleInvoicePage';
import { TechModePage } from './pages/TechModePage';
import { Page404 } from './pages/Page404';
import { Page500 } from './pages/Page500';
import { RegisterPage } from './pages/RegisterPage';
import { ForgotPasswordPage } from './pages/ForgotPasswordPage';
import { ResetPasswordPage } from './pages/ResetPasswordPage';
const theme = createTheme({
  palette: {
    primary: {
      main: '#003eab',
      light: '#1d55b8',
      dark: '#0b3785',
      contrastText: '#fff',
    },
    secondary: {
      main: '#303E9F12',
      light: '#4b5ac612',
      dark: '#29358712',
      contrastText: '#303E9F',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route exact path={url.home} element={<HomePage />} />
          <Route exact path={url.login} element={<LoginPage />} />
          <Route exact path={url.registration} element={<RegisterPage />} />
          <Route
            exact
            path={url.resetPassword}
            element={<ResetPasswordPage />}
          />
          <Route
            exact
            path={url.forgotPassword}
            element={<ForgotPasswordPage />}
          />
          <Route exact path={url.orders} element={<OrderListPage />} />
          <Route exact path={url.ordersDraft} element={<OrderListPage />} />
          <Route exact path={url.singleOrders} element={<SingleOrderPage />} />
          <Route exact path={url.draftOrder} element={<CreateOrder />} />
          <Route exact path={url.invoices} element={<InvoicesListPages />} />

          <Route exact path={url.techMode} element={<TechModePage />} />
          <Route exact path={url.error404} element={<Page404 />} />
          <Route exact path={url.error500} element={<Page500 />} />
          <Route exact path="*" element={<Page404 />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
