import { Box, Card, CardContent, Chip, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const OrderItem = ({ order }) => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  return (
    <Card
      onClick={() => navigate(`/orders/full/${order?.SalesOrderNumber}`)}
      sx={{ height: '142px', maxHeight: '142px' }}
    >
      <CardContent>
        <Stack
          direction="row"
          spacing={1}
          alignItems="start"
          justifyContent="space-between"
        >
          <Box>
            <Typography as="p" variant="p" sx={{ fontWeight: 'bold' }}>
              {order?.SalesOrderNumber}
            </Typography>
          </Box>

          <Box>
            <Typography>
              {order?.CustomerRequisitionNumber?.length > 20
                ? order?.CustomerRequisitionNumber?.slice(0, 20)
                : order?.CustomerRequisitionNumber}
            </Typography>
          </Box>
        </Stack>
        <Stack direction="row" spacing={1}>
          <Typography as="p" variant="caption">
            {t('Created')}
            {': '}
            {format(order?.OrderCreationDateTime, 'dd.MM.yyyy')}
          </Typography>
          <Typography as="p" variant="caption">
            {t('DeliveryDate')}
            {': '}
            {order?.ConfirmedShippingDate &&
            order?.ConfirmedShippingDate !== '1900-01-01T12:00:00Z'
              ? format(order?.ConfirmedShippingDate, 'dd.MM.yyyy')
              : order?.RequestedShippingDate &&
                order?.RequestedShippingDate !== '1900-01-01T12:00:00Z'
              ? format(order?.RequestedShippingDate, 'dd.MM.yyyy')
              : ''}
          </Typography>
        </Stack>
        <Box>
          {t('Amount') +
            ': ' +
            order?.OrderTotalAmount +
            ' ' +
            order?.CurrencyCode}
        </Box>
        <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
          {order?.ConfirmedShippingDate != '1900-01-01T12:00:00Z' &&
            order?.SalesOrderStatus == 'Backorder' && (
              <Chip
                label={t('CustomInProgress')}
                color="success"
                size="small"
                sx={{ mr: 1 }}
              />
            )}
          <Chip label={t(order?.SalesOrderStatus)} size="small" />
        </Stack>
      </CardContent>
    </Card>
  );
};
