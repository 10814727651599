import axios from 'axios';
import { BACKEND_URL } from '../utils/url';

export const GetCategories = async ({ token, top, skip, search }, lang) => {
  const { data } = await axios.get(`${BACKEND_URL}/v1/price/categories`, {
    headers: {
      Authorization: `Bearer ${token}`,
      language: lang,
    },
    params: {
      top,
      skip,
      search,
    },
  });
  return data;
};

export const GetProducts = async (
  { token, top, skip, categories, search, id = null },
  lang,
) => {
  const { data } = await axios.get(
    `${BACKEND_URL}/v1/price/get-my-price-list`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        language: lang,
      },
      params: {
        top,
        skip,
        categories,
        search,
        id,
      },
    },
  );
  return data;
};

export const GetUnitsOfMeasure = async ({ token }, lang) => {
  const { data } = await axios.get(`${BACKEND_URL}/v1/price/units-of-measure`, {
    headers: {
      Authorization: `Bearer ${token}`,
      language: lang,
    },
  });
  return data;
};
