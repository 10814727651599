import { Container } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { AlertsList } from '../AlertList/AlertList';
import { useSelector } from 'react-redux';
import { Header } from '../Header/Header';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { url } from '../../../utils/url';
import { useApi } from '../../../hooks/useApi';
import { CheckUnpaymentInvoices } from '../../../api/OrderApi';
import { GetSettings } from '../../../api/SettingsApi';

export const MainContainer = ({
  children,
  needAuth = true,
  setCheckhandler = () => {},
}) => {
  const alerts = useSelector((state) => state.alerts);
  const [cookie, setCookie] = useCookies(['jwt', 'lang']);
  const navigate = useNavigate();
  const [check, setCheck] = useState({});
  const [loadingPage, setLoadingPage] = useState(true);

  const CheckUnpaymentInvoicesApi = useApi(CheckUnpaymentInvoices);

  const GetSettingsApi = useApi(GetSettings);
  const [settings, setSettings] = useState({});

  const GetSettingsHandler = async () => {
    const result = await GetSettingsApi.sendRequest();
    if (result?.techMode) {
      navigate(url.techMode);
    } else {
      setLoadingPage(false);
    }
    setSettings(result);
  };

  useEffect(() => {
    if (cookie.jwt) {
      CheckUnpaymentInvoicesApi.sendRequest({ token: cookie.jwt }).then(
        (result) => {
          setCheckhandler(result);
          setCheck(result);
        },
      );
    }
    GetSettingsHandler();
    if (!cookie.lang) {
      setCookie('lang', 'en', { path: '/' });
    }
  }, []);

  useEffect(() => {
    if (needAuth && !cookie.jwt) {
      navigate(url.login);
    } else if (!needAuth && cookie.jwt) {
      navigate(url.orders);
    }
  }, [cookie]);
  return (
    settings &&
    !loadingPage && (
      <>
        <Header check={check} />
        <Container maxWidth="xl" sx={{ pt: 12 }}>
          {children}

          <AlertsList alerts={alerts} />
        </Container>
      </>
    )
  );
};
