import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  Fab,
  IconButton,
  Modal,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { BoxAndPalletReducer } from '../../utils/BoxAndPalletReducer';
import NoPhotographyOutlinedIcon from '@mui/icons-material/NoPhotographyOutlined';
import { useTranslation } from 'react-i18next';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { useResponsive } from '../../hooks/useResponsive';
export const OrderProductItem = ({
  price,
  selectedOrderPrice,
  showOtherData = false,
  sumProduct,
  minusProduct,
  changeItemCountField,
  disabled = false,
  showCounts = true,
  SalesOrderLineStatus = '',
  removeItem,
}) => {
  const { isMobile, isDesktop, isTablet } = useResponsive();
  const { t, i18n } = useTranslation();
  const [openImg, setOpenImg] = useState(false);
  useEffect(() => {}, []);

  return (
    <Card sx={{ mb: 1 }} variant="outlined">
      <CardContent>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Box
            sx={{
              width: '60px',
              minWidth: '60px',
              height: '60px',
              borderRadius: '5px',
              background: '#f1f1f1',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
            onClick={() => setOpenImg(true)}
          >
            {price?.releasedProduct?.img?.Attachment ? (
              <img
                alt=""
                src={`data:image/jpeg;base64,${price?.releasedProduct?.img?.Attachment}`}
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
            ) : (
              <NoPhotographyOutlinedIcon sx={{ opacity: '0.5' }} />
            )}
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <Typography>
              {price?.releasedProduct?.translation?.length
                ? price?.releasedProduct?.translation[0].Description ||
                  price?.releasedProduct?.translation[0].ProductName
                : price?.releasedProduct?.ProductSearchName}
            </Typography>
            <Stack direction="row" spacing={2}>
              {price?.releasedProduct?.ProductNumber && (
                <Typography sx={{ fontSize: '14px' }}>
                  {t('sku')}:{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    {price?.releasedProduct?.ProductNumber}
                  </span>
                </Typography>
              )}
              {price?.releasedProduct?.barcodes?.Barcode && (
                <Typography sx={{ fontSize: '14px' }}>
                  {t('barcode')}:{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    {price?.releasedProduct?.barcodes?.Barcode}
                  </span>
                </Typography>
              )}
              {price?.releasedProduct?.productDescription
                ?.CustomerProductNumber && (
                <Typography sx={{ fontSize: '14px' }}>
                  {t('customerCode')}:{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    {
                      price?.releasedProduct?.productDescription
                        ?.CustomerProductNumber
                    }
                  </span>
                </Typography>
              )}
            </Stack>
            {SalesOrderLineStatus && (
              <Chip
                sx={{ mt: 1 }}
                label={t(SalesOrderLineStatus)}
                size="small"
              />
            )}
          </Box>

          {isDesktop && (
            <>
              <Box sx={{ textAlign: 'right', minWidth: '120px' }}>
                <Typography as="p">
                  {(
                    price?.Price *
                    (selectedOrderPrice?.find(
                      (p) => p.priceId === price?.generatedId,
                    )?.count ||
                      price?.count ||
                      1)
                  ).toFixed(2)}{' '}
                  {price?.PriceCurrencyCode}
                </Typography>

                <Typography as="p" variant="caption">
                  {price?.Price} {price?.PriceCurrencyCode} x{' '}
                  {selectedOrderPrice?.find(
                    (p) => p.priceId === price?.generatedId,
                  )?.count ||
                    price?.count ||
                    0}{' '}
                  {t(price.QuantityUnitySymbolTranslate)}
                </Typography>
                {!showOtherData && (
                  <Box sx={{ minWidth: '120px' }}>
                    <Typography as="p" variant="caption">
                      (
                      {price?.BoxOrPalletMeasure?.box &&
                        `${
                          price?.BoxOrPalletMeasure?.box?.Factor +
                          ' ' +
                          t(price.QuantityUnitySymbolTranslate)
                        } ${t('in')} ${t('box')}`}
                      {price?.BoxOrPalletMeasure?.box &&
                      price?.BoxOrPalletMeasure?.pallet
                        ? ' / '
                        : ' '}
                      {price?.BoxOrPalletMeasure?.pallet &&
                        `${
                          price?.BoxOrPalletMeasure?.pallet?.Factor +
                          ' ' +
                          t(price.QuantityUnitySymbolTranslate)
                        } ${t('per')} ${t('pallet')}`}
                      )
                    </Typography>
                  </Box>
                )}
              </Box>

              {showCounts && (
                <Box sx={{ position: 'relative' }}>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Box>
                      <IconButton
                        sx={{ width: '24px', height: '24px' }}
                        size="small"
                        onClick={() => minusProduct(price)}
                        disabled={disabled}
                      >
                        -
                      </IconButton>
                    </Box>

                    <TextField
                      label={t('Qty')}
                      size="small"
                      placeholder="0"
                      disabled={disabled}
                      value={
                        selectedOrderPrice?.find(
                          (p) => p.priceId === price?.generatedId,
                        )?.count
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={({ target: { value } }) =>
                        changeItemCountField(price, +value)
                      }
                      sx={{ width: '80px', mb: 0 }}
                    />
                    <Box>
                      <IconButton
                        sx={{ width: '24px', height: '24px' }}
                        size="small"
                        disabled={disabled}
                        onClick={() => sumProduct(price)}
                      >
                        +
                      </IconButton>
                    </Box>
                  </Stack>
                </Box>
              )}
            </>
          )}
        </Stack>
        {(isMobile || isTablet) && (
          <>
            <Box sx={{ textAlign: 'right', minWidth: '120px' }}>
              <Typography as="p">
                {(
                  price?.Price *
                  (selectedOrderPrice?.find(
                    (p) => p.priceId === price?.generatedId,
                  )?.count ||
                    price?.count ||
                    1)
                ).toFixed(2)}{' '}
                {price?.PriceCurrencyCode}
              </Typography>

              <Typography as="p" variant="caption">
                {price?.Price} {price?.PriceCurrencyCode} x{' '}
                {selectedOrderPrice?.find(
                  (p) => p.priceId === price?.generatedId,
                )?.count ||
                  price?.count ||
                  0}{' '}
                {t(price.QuantityUnitySymbolTranslate)}
              </Typography>
              {!showOtherData && (
                <Box sx={{ minWidth: '120px' }}>
                  <Typography as="p" variant="caption">
                    (
                    {price?.BoxOrPalletMeasure?.box &&
                      `${
                        price?.BoxOrPalletMeasure?.box?.Factor +
                        ' ' +
                        t(price.QuantityUnitySymbolTranslate)
                      } ${t('in')} ${t('box')}`}
                    {price?.BoxOrPalletMeasure?.box &&
                    price?.BoxOrPalletMeasure?.pallet
                      ? ' / '
                      : ' '}
                    {price?.BoxOrPalletMeasure?.pallet &&
                      `${
                        price?.BoxOrPalletMeasure?.pallet?.Factor +
                        ' ' +
                        t(price.QuantityUnitySymbolTranslate)
                      } ${t('per')} ${t('pallet')}`}
                    )
                  </Typography>
                </Box>
              )}
            </Box>

            {showCounts && (
              <Box sx={{ position: 'relative' }}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Box>
                    <IconButton
                      sx={{ width: '24px', height: '24px' }}
                      size="small"
                      onClick={() => minusProduct(price)}
                      disabled={disabled}
                    >
                      -
                    </IconButton>
                  </Box>

                  <TextField
                    label={t('Qty')}
                    size="small"
                    placeholder="0"
                    disabled={disabled}
                    value={
                      selectedOrderPrice?.find(
                        (p) => p.priceId === price?.generatedId,
                      )?.count
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={({ target: { value } }) =>
                      changeItemCountField(price, +value)
                    }
                    sx={{ width: '80px', mb: 0 }}
                  />
                  <Box>
                    <IconButton
                      sx={{ width: '24px', height: '24px' }}
                      size="small"
                      disabled={disabled}
                      onClick={() => sumProduct(price)}
                    >
                      +
                    </IconButton>
                  </Box>
                </Stack>
              </Box>
            )}
          </>
        )}
        {showOtherData && (
          <>
            <Divider sx={{ my: 2 }} />

            <Stack
              direction={isDesktop ? 'row' : 'column'}
              spacing={2}
              sx={{ width: '100%' }}
              alignItems={isDesktop ? 'center' : 'start'}
            >
              <Box sx={{ width: isDesktop ? '20%' : '100%' }}>
                <Stack direction={isDesktop ? 'column' : 'row'} spacing={1}>
                  <Typography sx={{ fontWeight: 'bold' }}>
                    {t('NetWeight')}
                  </Typography>
                  <Typography>
                    {Math.abs(
                      BoxAndPalletReducer(
                        selectedOrderPrice?.find(
                          (p) => p?.priceId == price?.generatedId,
                        ) || price,
                        i18n.getDataByLanguage(i18n.language),
                      )?.NetProductWeightFull,
                    )}{' '}
                    {t('Kg')}
                  </Typography>
                </Stack>
              </Box>
              <Box sx={{ width: isDesktop ? '20%' : '100%' }}>
                <Stack direction={isDesktop ? 'column' : 'row'} spacing={1}>
                  <Typography sx={{ fontWeight: 'bold' }}>
                    {t('GrossWeight')}
                  </Typography>
                  <Typography>
                    {Math.abs(
                      BoxAndPalletReducer(
                        selectedOrderPrice?.find(
                          (p) => p.priceId == price?.generatedId,
                        ) || price,
                        i18n.getDataByLanguage(i18n.language),
                      )?.TareProductWeightFull,
                    )}{' '}
                    {t('Kg')}
                  </Typography>
                </Stack>
              </Box>
              <Box sx={{ width: isDesktop ? '60%' : '100%' }}>
                <Stack direction={isDesktop ? 'column' : 'row'} spacing={1}>
                  <Typography sx={{ fontWeight: 'bold' }}>
                    {t('Units')}{' '}
                    {(price?.BoxOrPalletMeasure?.box ||
                      price?.BoxOrPalletMeasure?.pallet) && (
                      <>
                        (
                        {price?.BoxOrPalletMeasure?.box &&
                          `${
                            price?.BoxOrPalletMeasure?.box?.Factor +
                            ' ' +
                            t(price.QuantityUnitySymbolTranslate)
                          } ${t('in')} ${t('box')}`}
                        {price?.BoxOrPalletMeasure?.box &&
                        price?.BoxOrPalletMeasure?.pallet
                          ? ' / '
                          : ' '}
                        {price?.BoxOrPalletMeasure?.pallet &&
                          `${
                            price?.BoxOrPalletMeasure?.pallet?.Factor +
                            ' ' +
                            t(price.QuantityUnitySymbolTranslate)
                          } ${t('per')} ${t('pallet')}`}
                        )
                      </>
                    )}
                  </Typography>
                  <Typography>
                    {
                      BoxAndPalletReducer(
                        price,
                        i18n.getDataByLanguage(i18n.language),
                      )?.stringResult
                    }
                  </Typography>
                </Stack>
              </Box>
              {showCounts && (
                <Box>
                  {isDesktop ? (
                    <Fab
                      sx={{
                        fontSize: '10px',
                        width: '30px',
                        height: '30px',
                        minHeight: '30px',
                      }}
                      variant="outlined"
                      color="error"
                      size="small"
                      onClick={() => removeItem(price)}
                    >
                      <DeleteForeverOutlinedIcon sx={{ fontSize: '20px' }} />
                    </Fab>
                  ) : (
                    <Button
                      color="error"
                      variant="outlined"
                      startIcon={<DeleteForeverOutlinedIcon />}
                    >
                      {t('Delete')}
                    </Button>
                  )}
                </Box>
              )}
            </Stack>
          </>
        )}
      </CardContent>

      <Modal
        open={openImg}
        onClose={() => setOpenImg(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box
          sx={{
            width: '90%',
            height: '90%',
            maxWidth: '460px',
            maxHeight: '460px',
            borderRadius: '5px',
            background: '#f1f1f1',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {price?.releasedProduct?.img?.Attachment ? (
            <img
              alt=""
              src={`data:image/jpeg;base64,${price?.releasedProduct?.img?.Attachment}`}
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          ) : (
            <NoPhotographyOutlinedIcon sx={{ opacity: '0.5' }} />
          )}
        </Box>
      </Modal>
    </Card>
  );
};
