export const BoxAndPalletReducer = (priceItem, language) => {
  const pallet = language?.translation['pallet'];
  const box = language?.translation['box'];
  if (priceItem) {
    const {
      Price,
      PriceCurrencyCode,
      count,
      QuantityUnitySymbol,
      releasedProduct,
    } = priceItem;
    const PalletFactor = +priceItem?.BoxOrPalletMeasure?.pallet?.Factor || 0;
    const BoxFactor = +priceItem?.BoxOrPalletMeasure?.box?.Factor || 1;
    return {
      Price,
      PriceCurrencyCode,
      count,
      QuantityUnitySymbol,
      PalletFactor,
      BoxFactor,
      NetProductWeightFull: (
        releasedProduct?.NetProductWeight * (count || 0) || 0
      )?.toFixed(3),
      TareProductWeightFull: (
        +(+releasedProduct?.NetProductWeight * (count || 0) || 0)?.toFixed(3) +
        +(+releasedProduct?.TareProductWeight * (count || 0) || 0)?.toFixed(3)
      ).toFixed(3),
      stringResult: `${Price} ${PriceCurrencyCode} x ${
        count || 0
      } ${QuantityUnitySymbol}.\n(${Math.abs(
        ((count || 0) / BoxFactor)?.toFixed(2),
      )} ${box} ${
        PalletFactor
          ? `/ ${Math.abs(((count || 0) / PalletFactor)?.toFixed(2))} ${pallet}`
          : ''
      })`,
    };
  }
};
