import axios from 'axios';
import { BACKEND_URL } from '../utils/url';

export const GetMyOrders = async (
  { token, skip = 0, top = 16, search = '' },
  lang,
) => {
  const { data } = await axios.get(`${BACKEND_URL}/v1/order/get-my-orders`, {
    params: {
      skip,
      top,
      search,
    },
    headers: {
      Authorization: `Bearer ${token}`,
      language: lang,
    },
  });
  return data;
};
export const GetMyDrafts = async ({ token, skip = 0, top = 16 }, lang) => {
  const { data } = await axios.get(`${BACKEND_URL}/v1/order/get-my-drafts`, {
    params: {
      skip,
      top,
    },
    headers: {
      Authorization: `Bearer ${token}`,
      language: lang,
    },
  });
  return data;
};

export const GetMySingleOrder = async ({ token, SalesOrderNumber }, lang) => {
  const { data } = await axios.get(
    `${BACKEND_URL}/v1/order/single/${SalesOrderNumber}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        language: lang,
      },
    },
  );
  return data;
};

export const GetOrderLines = async ({ token, SalesOrderNumber }, lang) => {
  const { data } = await axios.get(
    `${BACKEND_URL}/v1/order/get-order-lines/${SalesOrderNumber}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        language: lang,
      },
    },
  );
  return data;
};

export const GetOrderInvoices = async ({ token, SalesOrderNumber }, lang) => {
  const { data } = await axios.get(
    `${BACKEND_URL}/v1/order/invoices/${SalesOrderNumber}`,

    {
      headers: {
        Authorization: `Bearer ${token}`,
        language: lang,
      },
    },
  );
  return data;
};

export const PostDraftOrder = async ({ token, body }, lang) => {
  const { data } = await axios.post(`${BACKEND_URL}/v1/order/draft`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
      language: lang,
    },
  });
  return data;
};

export const GetAddresses = async ({ token }, lang) => {
  const { data } = await axios.get(`${BACKEND_URL}/v1/order/get-addresses`, {
    headers: {
      Authorization: `Bearer ${token}`,
      language: lang,
    },
  });
  return data;
};

export const GetDraftById = async ({ token, draftId }, lang) => {
  const { data } = await axios.get(`${BACKEND_URL}/v1/order/draft/${draftId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      language: lang,
    },
  });
  return data;
};

export const GetInvoices = async ({ token, top, skip, search = '' }, lang) => {
  const { data } = await axios.get(`${BACKEND_URL}/v1/order/get-invoices`, {
    headers: {
      Authorization: `Bearer ${token}`,
      language: lang,
    },
    params: {
      top,
      skip,
      search,
    },
  });
  return data;
};
export const GetSingleInvoice = async ({ token, InvoiceId }, lang) => {
  const { data } = await axios.get(`${BACKEND_URL}/v1/order/invoices-get-one`, {
    params: {
      InvoiceId,
    },
    headers: {
      Authorization: `Bearer ${token}`,
      language: lang,
    },
  });
  return data[0];
};

export const GetSingleInvoiceLines = async (
  { token, InvoiceId, InvoiceDate },
  lang,
) => {
  const { data } = await axios.get(
    `${BACKEND_URL}/v1/order/get-invoice-trans`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        language: lang,
      },
      params: {
        InvoiceId,
        InvoiceDate,
      },
    },
  );
  return data;
};

export const CheckUnpaymentInvoices = async ({ token }, lang) => {
  const { data } = await axios.get(
    `${BACKEND_URL}/v1/order/check-unpayment-invoices`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        language: lang,
      },
    },
  );
  return data;
};

export const CreateOrderToD365 = async ({ token, draftId }, lang) => {
  const { data } = await axios.post(
    `${BACKEND_URL}/v1/order/create`,
    {
      draftId,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        language: lang,
      },
    },
  );
  return data;
};

export const RemoveDraftOrder = async ({ token, draftId }, lang) => {
  const { data } = await axios.post(
    `${BACKEND_URL}/v1/order/remove-draft`,
    {
      draftId,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        language: lang,
      },
    },
  );
  return data;
};

export const GetMyTransaction = async ({ token }, lang) => {
  const { data } = await axios.get(`${BACKEND_URL}/v1/order/transactions`, {
    headers: {
      Authorization: `Bearer ${token}`,
      language: lang,
    },
  });
  return data;
};
