import React, { useEffect, useState } from 'react';
import { MainContainer } from '../components/Default/Layout/MainContainer';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import validator from 'validator';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { useApi } from '../hooks/useApi';
import { Auth } from '../api/AuthApi';
import { useCookies } from 'react-cookie';
import { add } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { url } from '../utils/url';

export const LoginPage = () => {
  const { t } = useTranslation();
  const [cookie, setCookie] = useCookies('jwt');
  const [form, setForm] = useState({ email: '', passwrod: '' });
  const [emailHelperText, setEmailHelperText] = useState('');
  const [emailError, setEmailError] = useState(false);
  const AuthApi = useApi(Auth);
  const navigate = useNavigate();
  useEffect(() => {
    if (form.email && !validator.isEmail(form.email)) {
      setEmailError(true);
      setEmailHelperText(t('LoginEmailFieldHelperTextError'));
    } else {
      setEmailError(false);
      setEmailHelperText('');
      //t('LoginEmailFieldHelperTextDefault')
    }
  }, [form]);

  const AuthHandler = async () => {
    try {
      const { accessToken } = await AuthApi.sendRequest({
        email: form.email,
        password: form.password,
      });

      if (accessToken) {
        setCookie('jwt', accessToken, {
          expires: add(new Date(), {
            days: 1800,
          }),
          path: '/',
        });
        navigate(url.orders);
      }
    } catch (error) {}
  };
  return (
    <MainContainer needAuth={false}>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        sx={{ minHeight: '80vh' }}
      >
        <Grid item xs={12} sm={6} lg={4}>
          <Card variant="outlined" sx={{ borderRadius: '10px' }}>
            <CardContent>
              <Typography as="h1" variant="h6" sx={{ mb: 2 }}>
                {t('LoginPageTitle')}
              </Typography>
              <TextField
                label={t('EmailFieldLabel')}
                helperText={emailHelperText}
                error={emailError}
                fullWidth
                sx={{ mb: 2 }}
                disabled={AuthApi.loading}
                value={form.email}
                type="email"
                onChange={({ target: { value } }) =>
                  setForm({ ...form, email: value })
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                label={t('PasswordFieldLabel')}
                sx={{ mb: 2 }}
                type="password"
                fullWidth
                disabled={AuthApi.loading}
                value={form.password}
                onChange={({ target: { value } }) =>
                  setForm({ ...form, password: value })
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <LoadingButton
                variant="contained"
                color="primary"
                disabled={emailError || !form?.password}
                onClick={() => AuthHandler()}
                loading={AuthApi.loading}
              >
                {t('ContinueButton')}
              </LoadingButton>
            </CardContent>
          </Card>
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="center"
            sx={{ mt: 2 }}
          >
            <Box>
              <Link
                color="inherit"
                sx={{ textDecoration: 'none', opacity: 0.5, cursor: 'pointer' }}
                onClick={() => navigate(url.registration)}
              >
                {t('registrationLink')}
              </Link>
            </Box>
            <Box>
              <Link
                color="inherit"
                sx={{ textDecoration: 'none', opacity: 0.5, cursor: 'pointer' }}
                onClick={() => navigate(url.forgotPassword)}
              >
                {t('forgotPasswordLink')}
              </Link>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </MainContainer>
  );
};
