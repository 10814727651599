import { configureStore } from '@reduxjs/toolkit';
import alertReducer from './alert.store';
import transactionReducer from './transaction.store';

export default configureStore({
  reducer: {
    alerts: alertReducer,
    transactionsStore: transactionReducer,
  },
});
