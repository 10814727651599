import { Box, Button, CircularProgress, Typography } from '@mui/material';

import React from 'react';
import { url } from '../utils/url';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const Page404 = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box sx={{ width: '500px', textAlign: 'center' }}>
        <Typography as="h1" variant="h6">
          404
        </Typography>
        <Typography>{t('Error404')}</Typography>
        <Button
          variant="outlined"
          color="primary"
          sx={{ mt: 3 }}
          onClick={() => navigate(url.orders)}
        >
          {t('GoToMainPage')}
        </Button>
      </Box>
    </Box>
  );
};
