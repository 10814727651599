import React, { useEffect, useState } from 'react';
import { MainContainer } from '../components/Default/Layout/MainContainer';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { useApi } from '../hooks/useApi';
import { useNavigate, useParams } from 'react-router-dom';
import { url } from '../utils/url';
import { useDispatch } from 'react-redux';
import { setAlert } from '../store/alert.store';
import { ResetPassword } from '../api/UserApi';

export const ResetPasswordPage = () => {
  const { t } = useTranslation();

  const [form, setForm] = useState({
    password: '',
    passwordAgain: '',
  });

  const [passwordHelperText, setPasswordHelperText] = useState('');

  const [passwordError, setPasswordError] = useState(false);
  const ResetPasswordApi = useApi(ResetPassword);
  const navigate = useNavigate();
  useEffect(() => {
    if (
      form.password &&
      form.passwordAgain &&
      form.password !== form.passwordAgain
    ) {
      setPasswordError(true);
      setPasswordHelperText(t('LoginPasswordFieldHelperTextError'));
    } else {
      setPasswordError(false);
      setPasswordHelperText('');
    }
  }, [form]);

  const dispatch = useDispatch();
  const { forgotPasswordId } = useParams();

  const ResetPassordHandler = async () => {
    try {
      const result = await ResetPasswordApi.sendRequest({
        password: form.password,
        forgotPasswordId,
      });

      if (result?.id) {
        dispatch(setAlert({ text: t('SuccessResetPassword'), status: 200 }));
        navigate(url.login);
      }
    } catch (error) {}
  };
  return (
    <MainContainer needAuth={false}>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        sx={{ minHeight: '80vh' }}
      >
        <Grid item xs={12} sm={6} lg={4}>
          <Card variant="outlined" sx={{ borderRadius: '10px' }}>
            <CardContent>
              <Typography as="h1" variant="h6" sx={{ mb: 2 }}>
                {t('ForgotPasswordPageTitle')}
              </Typography>

              <TextField
                label={t('PasswordFieldLabel')}
                sx={{ mb: 2 }}
                type="password"
                fullWidth
                disabled={ResetPasswordApi.loading}
                value={form.password}
                error={passwordError}
                onChange={({ target: { value } }) =>
                  setForm({ ...form, password: value })
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                label={t('PasswordAgainFieldLabel')}
                sx={{ mb: 2 }}
                type="password"
                fullWidth
                helperText={passwordHelperText}
                error={passwordError}
                disabled={ResetPasswordApi.loading}
                value={form.passwordAgain}
                onChange={({ target: { value } }) =>
                  setForm({ ...form, passwordAgain: value })
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <LoadingButton
                variant="contained"
                color="primary"
                disabled={
                  passwordError || !form?.password || !form.passwordAgain
                }
                onClick={() => ResetPassordHandler()}
                loading={ResetPasswordApi.loading}
              >
                {t('Continue')}
              </LoadingButton>
            </CardContent>
          </Card>
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="center"
            sx={{ mt: 2 }}
          >
            <Box>
              <Link
                color="inherit"
                sx={{ textDecoration: 'none', opacity: 0.5, cursor: 'pointer' }}
                onClick={() => navigate(url.registration)}
              >
                {t('registrationLink')}
              </Link>
            </Box>
            <Box>
              <Link
                color="inherit"
                sx={{ textDecoration: 'none', opacity: 0.5, cursor: 'pointer' }}
                onClick={() => navigate(url.login)}
              >
                {t('loginLink')}
              </Link>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </MainContainer>
  );
};
