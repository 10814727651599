import axios from 'axios';
import { BACKEND_URL } from '../utils/url';

export const SignUp = async ({ email = null, password = null }, lang) => {
  const { data } = await axios.post(
    `${BACKEND_URL}/v1/user/signup`,
    {
      email,
      password,
    },
    {
      headers: {
        language: lang,
      },
    },
  );
  return data;
};

export const ForgotPassword = async ({ email = null }, lang) => {
  const { data } = await axios.post(
    `${BACKEND_URL}/v1/user/forgot-password`,
    {
      email,
    },
    {
      headers: {
        language: lang,
      },
    },
  );
  return data;
};

export const ResetPassword = async (
  { password = null, forgotPasswordId = null },
  lang,
) => {
  const { data } = await axios.post(
    `${BACKEND_URL}/v1/user/reset-password`,
    {
      password,
      forgotPasswordId,
    },
    {
      headers: {
        language: lang,
      },
    },
  );
  return data;
};
