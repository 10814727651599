// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header_Header__Menu__9Xcl8 a {
  color: #000;
  text-decoration: none;
  padding: 0px 0px;
  padding-bottom: 5px;
  margin: 0 10px;
}
.Header_Header__MenuItem__nweR5 {
  border-bottom: solid 2px #003eab;
}`, "",{"version":3,"sources":["webpack://./src/components/Default/Header/Header.module.scss"],"names":[],"mappings":"AAEQ;EACI,WAAA;EACA,qBAAA;EACA,gBAAA;EACA,mBAAA;EACA,cAAA;AADZ;AAII;EACI,gCAAA;AAFR","sourcesContent":[".Header{\n    &__Menu{\n        a {\n            color: #000;\n            text-decoration: none;\n            padding: 0px 0px;\n            padding-bottom: 5px;\n            margin: 0 10px;\n        }\n    }\n    &__MenuItem {\n        border-bottom: solid 2px #003eab;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Header__Menu": `Header_Header__Menu__9Xcl8`,
	"Header__MenuItem": `Header_Header__MenuItem__nweR5`
};
export default ___CSS_LOADER_EXPORT___;
