import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { Translation } from 'react-i18next';

const humanReadbleError = (error, statusCode) => {
  let text = '';
  switch (statusCode) {
    case 500:
      text = <Translation>{(t) => t('error500Alert')}</Translation>;
      break;
    case 502:
      text = <Translation>{(t) => t('error502Alert')}</Translation>;
      break;
    case 504:
      text = <Translation>{(t) => t('error504Alert')}</Translation>;
      break;
    case 400:
      text = <Translation>{(t) => t('error400Alert')}</Translation>;
      break;
    case 401:
      text = <Translation>{(t) => t('error401Alert')}</Translation>;
      break;
    case 403:
      text = <Translation>{(t) => t('error403Alert')}</Translation>;
      break;
    case 404:
      text = <Translation>{(t) => t('error404Alert')}</Translation>;
      break;
    case 200:
      text = error.text;
      break;
    case 201:
      text = error.text;
      break;
    default:
      break;
  }
  return text;
};
export const alertSlice = createSlice({
  name: 'user',
  initialState: [],
  reducers: {
    setAlert: (state, action) => {
      const error = action?.payload?.response?.data;
      if (action?.payload[0]?.id) {
        action.payload[0].id = uuidv4();
      }
      let data = [];
      const statusCode =
        error?.statusCode ||
        action?.payload?.response?.status ||
        action?.payload?.statusCode ||
        action?.payload?.status ||
        500;

      if (typeof action?.payload == 'object' && action?.payload?.length) {
        data = {
          id: uuidv4(),
          text: humanReadbleError(action?.payload, statusCode),
          type: [201, 200].includes(statusCode) ? 'success' : 'error',
          details: error?.detail || action?.payload?.detail,
          message: error?.message,
        };
      } else if (typeof action?.payload == 'object') {
        data = [
          {
            id: uuidv4(),
            text: humanReadbleError(action?.payload, statusCode),
            type: [201, 200].includes(statusCode) ? 'success' : 'error',
            details: error?.detail || action?.payload?.detail,
            message: error?.message,
          },
        ];
      }

      return [...state, ...data];
    },
    removeAlert: (state, action) => {
      const newState = state.filter((s, index) => s.id !== action.payload.id);
      return newState;
    },
  },
});
export const { setAlert, removeAlert } = alertSlice.actions;
export default alertSlice.reducer;
