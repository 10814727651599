import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const Page500 = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box sx={{ width: '500px', textAlign: 'center' }}>
        <Typography as="h1" variant="h6">
          500
        </Typography>
        <Typography>{t('Error500')}</Typography>
      </Box>
    </Box>
  );
};
