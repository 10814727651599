import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { url } from '../utils/url';

export const HomePage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(url.login);
  }, []);
  return <div>HomePage</div>;
};
